import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, Grid, Skeleton, Stack, TextField, Typography } from "@mui/material";
import Indicator from "../../../../../components/Loader";
import FilterAccordion from "../../../../../components/Filters";
import CommonMnAFilters from "../../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import TextFieldNumber from "../../../../../components/TextFieldNumber/TextFieldNumber";
import dataObject from "../../../../../mocks/assortmentTool";
import { OrangeBtn, PrimaryBtn } from "../../../../../styles/Common.Styled";
import { IAssortmentListPayload, IAssortmentPenetrationLimit, IMnAFilterConfigurations } from "../../../../../types/mixAndAssortment";
import { getTextWidth } from "../../../../../util/helper";
import { fetchAssortmentAverageData } from "../../../../../util/mixAndAssortmentServices";
import { toast } from "../../../../../store/actions/toast.action";

/* Check if apply button needs to be disabled.
 * @param {IMnAFilterConfigurations} selectedFilters - The object of selected filter values.
 * @param {IAssortmentPenetrationLimit} selectedPenetrationLimit - Selected penetration limit values.
 * @returns true if any required value is missing else return false.
 */
function disableApplyBtn(selectedFilters: IMnAFilterConfigurations, selectedPenetrationLimit: IAssortmentPenetrationLimit) {
	if (
		selectedFilters.channel.length === 0 ||
		selectedFilters.region.length === 0 ||
		selectedFilters.storeSegment.length === 0 ||
		selectedPenetrationLimit.goodToHaveSKU === null ||
		selectedPenetrationLimit.mustHaveSKU === null
	) {
		return true;
	}
	return false;
}

const GeoSKUFilters: FC<{
	selectedFilters: IMnAFilterConfigurations;
	selectedPenetrationLimit: IAssortmentPenetrationLimit;
	geoFilterSkeleton: boolean;
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
	setSelectedPenetrationLimit: Dispatch<SetStateAction<IAssortmentPenetrationLimit>>;
	callBack: (payload: IAssortmentListPayload) => void;
}> = ({ selectedFilters, selectedPenetrationLimit, geoFilterSkeleton = false, setSelectedFilters, setSelectedPenetrationLimit, callBack }) => {
	const dispatch = useDispatch();
	const scenarioDetail = useSelector((state: RootStateOrAny) => state.assortmentDetail.data);
	const [geoFilterData, setGeoFilterData] = useState(dataObject.geoFilter);
	const [geoFilterOption, setGeoFilterOption] = useState<IMnAFilterConfigurations>(dataObject.overallFilterOptions);
	const [clearGeoFilterFlag, setClearGeoFilterFlag] = useState(false);
	const [message, setMessage] = useState("");
	const [isLoaderVisible, setIsLoaderVisible] = useState(false);

	useEffect(() => {
		if (scenarioDetail && scenarioDetail.assortmentGeoFilters) {
			let channelFilterOptions: string[] = [];
			channelFilterOptions = Array.from(
				new Set(
					scenarioDetail.assortmentGeoFilters.map((geoFilters) => {
						return geoFilters.channel;
					})
				)
			);
			channelFilterOptions = channelFilterOptions.sort((a: string, b: string) => {
				if (a > b) {
					return 1;
				} else {
					return -1;
				}
			});
			let regionFilterOptions: string[] = [];
			let selectedGeoFilters = scenarioDetail.assortmentGeoFilters.filter((geoFilters) => {
				return selectedFilters.channel.indexOf(geoFilters.channel) !== -1;
			});
			regionFilterOptions = Array.from(
				new Set(
					selectedGeoFilters.map((geoFilters) => {
						return geoFilters.region;
					})
				)
			);
			regionFilterOptions = regionFilterOptions.sort((a: string, b: string) => {
				if (a > b) {
					return 1;
				} else {
					return -1;
				}
			});
			let storeSegmentFilterOptions: string[] = [];
			selectedGeoFilters = selectedGeoFilters.filter((geoFilters) => {
				return selectedFilters.region.indexOf(geoFilters.region) !== -1;
			});
			storeSegmentFilterOptions = Array.from(
				new Set(
					selectedGeoFilters.map((geoFilters) => {
						return geoFilters.storeSegment;
					})
				)
			);
			storeSegmentFilterOptions = storeSegmentFilterOptions.sort((a: string, b: string) => {
				if (a > b) {
					return 1;
				} else {
					return -1;
				}
			});
			setGeoFilterOption({ ...geoFilterOption, channel: channelFilterOptions, region: regionFilterOptions, storeSegment: storeSegmentFilterOptions });
			setGeoFilterData({
				...geoFilterData,
				region: { ...geoFilterData.region, disabled: !regionFilterOptions || regionFilterOptions.length === 0 },
				storeSegment: { ...geoFilterData.storeSegment, disabled: !storeSegmentFilterOptions || storeSegmentFilterOptions.length === 0 },
			});
		} else if (scenarioDetail && scenarioDetail.message) {
			setMessage(scenarioDetail.message);
		}
	}, [scenarioDetail, selectedFilters]);

	useEffect(() => {
		if (!clearGeoFilterFlag) {
			let isFilterChangeFlag: boolean = false;
			const defaultSelectedFilters: IMnAFilterConfigurations = { ...selectedFilters };
			if (selectedFilters.channel.length === 0 && geoFilterOption.channel[0]) {
				defaultSelectedFilters.channel = [geoFilterOption.channel[0]];
				isFilterChangeFlag = true;
			}
			if (selectedFilters.region.length === 0 && geoFilterOption.region[0]) {
				defaultSelectedFilters.region = [geoFilterOption.region[0]];
				isFilterChangeFlag = true;
			}
			if (selectedFilters.storeSegment.length === 0 && geoFilterOption.storeSegment[0]) {
				defaultSelectedFilters.storeSegment = [geoFilterOption.storeSegment[0]];
				isFilterChangeFlag = true;
			}
			if (isFilterChangeFlag) {
				setSelectedFilters({ ...defaultSelectedFilters });
			}
		}
	}, [geoFilterOption, selectedFilters, clearGeoFilterFlag]);

	useEffect(() => {
		if (selectedFilters.storeSegment[0]) {
			getAssortmentPenetrationData();
		}
	}, [selectedFilters.storeSegment]);

	const getAssortmentPenetrationData = () => {
		setIsLoaderVisible(true);
		const payload: IAssortmentListPayload = {
			mustHaveSKU: 0,
			goodToHaveSKU: 0,
			region: selectedFilters.region[0],
			channel: selectedFilters.channel[0],
			storeSegment: selectedFilters.storeSegment[0],
			scenarioName: scenarioDetail.scenarioName,
		};
		fetchAssortmentAverageData(payload)
			.then((response) => {
				if (response && response.data) {
					setSelectedPenetrationLimit({
						mustHaveSKU: Math.round(response.data.mustHaveSKU * 100),
						mustHaveSkuCurrentAverage: Math.round(response.data.mustHaveSkuCurrentAverage * 100),
						goodToHaveSKU: Math.round(response.data.goodToHaveSKU * 100),
						goodToHaveSkuCurrentAverage: Math.round(response.data.goodToHaveSkuCurrentAverage * 100),
					});
				} else if (response && response.error) {
					dispatch(toast("Assortment Current Average: " + response.error, true, 2000, "error"));
				}
			})
			.finally(() => {
				setIsLoaderVisible(false);
			});
	};

	const onChangeFilter = (key, value) => {
		setClearGeoFilterFlag(true);
		let selectedValues = { ...selectedFilters };
		switch (key) {
			case "channel":
				selectedValues = { ...selectedValues, channel: value, region: [], storeSegment: [] };
				break;
			case "region":
				selectedValues = { ...selectedValues, region: value, storeSegment: [] };
				break;
			case "storeSegment":
				selectedValues = { ...selectedValues, storeSegment: value };
		}
		setSelectedFilters(selectedValues);
	};

	const clearGeoFilter = () => {
		setClearGeoFilterFlag(true);
		setSelectedFilters({ ...selectedFilters, channel: [], region: [], storeSegment: [] });
		setSelectedPenetrationLimit({ ...selectedPenetrationLimit, mustHaveSKU: 0, goodToHaveSKU: 0 });
	};

	const applyGeoFilters = () => {
		const payload: IAssortmentListPayload = {
			mustHaveSKU: selectedPenetrationLimit.mustHaveSKU,
			goodToHaveSKU: selectedPenetrationLimit.goodToHaveSKU,
			region: selectedFilters.region[0],
			channel: selectedFilters.channel[0],
			storeSegment: selectedFilters.storeSegment[0],
			scenarioName: scenarioDetail.scenarioName,
		};
		callBack(payload);
	};
	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<CardContent style={{ padding: "15px 15px 15px" }}>
				{geoFilterSkeleton ? (
					<Grid item xs={12}>
						<Skeleton variant="rectangular" height={250} />
					</Grid>
				) : (
					<>
						<Indicator position="absolute" show={isLoaderVisible} />
						<Grid style={{ marginTop: "10px" }}>
							<FilterAccordion title="Geo-Filters" expandFlag={true}>
								<Grid container>
									{message ? (
										<Grid color={"#FF0000"}>{message}</Grid>
									) : (
										<Grid item sm={9} className=" MuiGrid-item">
											<CommonMnAFilters
												filterData={geoFilterOption}
												onChange={onChangeFilter}
												data={geoFilterData}
												defaultFilters={selectedFilters}
												showSkeleton={false}
												filterOrder={dataObject.assortmentGeoFilterOrder}
											/>
										</Grid>
									)}
								</Grid>
							</FilterAccordion>
						</Grid>
						<Grid container>
							<Grid item xs={12} className="m-b-10" style={{ marginTop: "10px", marginLeft: "16px" }}>
								<Grid xs={12} display={"flex"}>
									<Grid xs={6}>
										<Typography className="f-w-5 m-b-20" color={"#7E7E7E"}>
											<span style={{ font: "normal normal 400 16px" }} dangerouslySetInnerHTML={{ __html: "Assign Penetration Target" }}></span>
										</Typography>
									</Grid>
									<Grid xs={6} spacing={2} columns={4} display={"flex"} flexDirection={"row"} marginLeft={"left"} justifyContent={"end"}>
										<Grid item>
											<Typography color={"#000000"} fontSize={10}>
												<b>*NOTE:</b> Assigned Penetration (Distribution) Target can individually be 0-100%.
											</Typography>
										</Grid>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="m-b-20">
									<Grid item xl={9} lg={12} columns={4} display={"flex"} flexDirection={"row"} alignItems={"left"}>
										<Grid item sm={4}>
											<Stack direction={"row"} alignItems={"center"} spacing={2}>
												<Grid item sm={7} textAlign={"left"}>
													<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
														Must have SKU*
													</Typography>
												</Grid>
												<Grid item sm={3}>
													<Box display="flex" alignItems="center">
														<TextFieldNumber
															defaultValue={selectedPenetrationLimit.mustHaveSKU}
															callback={(value) => {
																setSelectedPenetrationLimit({ ...selectedPenetrationLimit, mustHaveSKU: value });
															}}
														/>
														{selectedPenetrationLimit.mustHaveSKU !== null && (
															<Box
																component={"span"}
																style={{ marginLeft: getTextWidth(Math.round(selectedPenetrationLimit.mustHaveSKU)) + 15, position: "absolute" }}
															>
																%
															</Box>
														)}
													</Box>
												</Grid>
											</Stack>
										</Grid>
										<Grid item sm={4}>
											<Stack direction={"row"} alignItems={"center"} spacing={2} alignContent={"end"} justifyContent={"end"}>
												<Grid item sm={7} textAlign={"left"}>
													<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
														Good to have SKU*
													</Typography>
												</Grid>
												<Grid item sm={3}>
													<Box display="flex" alignItems="center">
														<TextFieldNumber
															defaultValue={selectedPenetrationLimit.goodToHaveSKU}
															callback={(value) => {
																setSelectedPenetrationLimit({ ...selectedPenetrationLimit, goodToHaveSKU: value });
															}}
														/>
														{selectedPenetrationLimit.goodToHaveSKU !== null && (
															<Box
																component={"span"}
																style={{ marginLeft: getTextWidth(Math.round(selectedPenetrationLimit.goodToHaveSKU)) + 15, position: "absolute" }}
															>
																%
															</Box>
														)}
													</Box>
												</Grid>
											</Stack>
										</Grid>
									</Grid>
									<Grid item xl={9} lg={12} columns={4} display={"flex"} flexDirection={"row"} alignItems={"left"}>
										<Grid item sm={4}>
											<Stack direction={"row"} alignItems={"center"} spacing={2}>
												<Grid item sm={7} textAlign={"left"}>
													<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
														Current Average*
													</Typography>
												</Grid>
												<Grid item sm={3}>
													<TextField type="text" value={selectedPenetrationLimit.mustHaveSkuCurrentAverage + "%"} disabled />
												</Grid>
											</Stack>
										</Grid>
										<Grid item sm={4}>
											<Stack direction={"row"} alignItems={"center"} spacing={2} alignContent={"end"} justifyContent={"end"}>
												<Grid item sm={7} textAlign={"left"}>
													<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
														Current Average*
													</Typography>
												</Grid>
												<Grid item sm={3}>
													<TextField type="text" value={selectedPenetrationLimit.goodToHaveSkuCurrentAverage + "%"} disabled />
												</Grid>
											</Stack>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid className="p-l-16">
							<OrangeBtn color="secondary" className="m-r-20" onClick={clearGeoFilter}>
								Clear Filter
							</OrangeBtn>
							<PrimaryBtn color="primary" onClick={applyGeoFilters} disabled={disableApplyBtn(selectedFilters, selectedPenetrationLimit)}>
								Apply Filter
							</PrimaryBtn>
						</Grid>
					</>
				)}
			</CardContent>
		</Card>
	);
};

export default GeoSKUFilters;
