import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import HighchartContainer from "../../../../components/HighchartContainer";
import CircularProgressBar from "../../../../components/CircularProgressBar/CircularProgressBar";
import dataObject from "../../../../mocks/executionTracker";

const cardColor = (score: number) => {
	let color = "";
	let bgColor = "";
	if (score < 40) {
		color = "#FA4242";
		bgColor = "#FEEEEE";
	}
	if (score >= 40 && score <= 70) {
		color = "#F8BD0E";
		bgColor = "#f8bd0e1a";
	}
	if (score > 70) {
		color = "#27AF8D";
		bgColor = "#16c79a1a";
	}
	return { color: color, cardColor: bgColor };
};

function getLabelFromKey(key: string): string {
	const foundItem = dataObject.simulationDataKeys.find((item) => item.value === key);
	return foundItem ? foundItem.label : key; // Fallback to the key itself if not found
}

const SimulationCard: React.FC<{ data; chartSkeleton?: boolean }> = ({ data, chartSkeleton }) => {
	const [sections, setSections] = useState<string[]>([]);
	useEffect(() => {
		if (data) {
			const sectionValues = Object.keys(data).filter((key) => key !== "overallScore" && key != "currentAverage");
			setSections(sectionValues);
		}
	}, [data]);

	return !chartSkeleton && data ? (
		<>
			<Grid item sm={6}>
				<HighchartContainer id={1}>
					<CircularProgressBar data={parseFloat(Number(data.overallScore).toFixed(1))} chartRef={null} />
				</HighchartContainer>
			</Grid>
			<Grid container spacing={2}>
				{sections.map((sectionKey) => {
					const sectionData = data[sectionKey];
					const filteredData = Object.entries(sectionData).filter(([key]) => key !== "overallScore" && key !== "status");
					const cardStyle = cardColor(Number(sectionData.overallScore));
					return (
						<Grid item xs={12} md={6} key={sectionKey}>
							<Box style={{ padding: "10px", height: 150 }}>
								<Typography fontSize={16} color="#000000" marginBottom="5px">
									{getLabelFromKey(sectionKey)}
								</Typography>
								<Stack direction="row" alignItems="center" justifyContent="space-between" marginBottom={1}>
									<Typography color="#000000" fontWeight={600}>
										{Number(sectionData.overallScore).toFixed(1)}
										<span style={{ color: "#7E7E7E" }}> /100</span>
									</Typography>
									<Box sx={{ width: "150px", background: cardStyle.cardColor, display: "flex", justifyContent: "center" }}>
										<Typography fontWeight={600} color={cardStyle.color}>
											{sectionData.status}
										</Typography>
									</Box>
								</Stack>
								{filteredData.map(([key, value]) => (
									<Grid container display="flex" justifyContent="space-between">
										<Grid item xs={12} md={6}>
											<Typography fontSize={14} color="#000000" key={key}>
												{getLabelFromKey(key)}
											</Typography>
										</Grid>
										<Grid item>
											<Box sx={{ width: "150px", display: "flex", justifyContent: "center" }}>
												<Typography fontSize={14} color="#000000" key={`${key}-value`}>
													{key !== "status" ? Number(value).toFixed(1) : value} <span style={{ color: "#7E7E7E" }}> /100</span>
												</Typography>
											</Box>
										</Grid>
									</Grid>
								))}
							</Box>
						</Grid>
					);
				})}
			</Grid>
		</>
	) : (
		<>
			<Grid item sm={6}>
				<Skeleton variant="rectangular" width={400} height={430} />
			</Grid>
			<Grid container spacing={2}>
				{[0, 1, 2].map((card) => {
					return (
						<Grid item xs={12} key={card}>
							<Skeleton variant="rectangular" height={130} />
						</Grid>
					);
				})}
			</Grid>
		</>
	);
};

export default SimulationCard;
