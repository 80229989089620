import { Box, Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { IStats } from "../../../../types/common";
import StatCard from "../../../../components/StatCard";
import dataObject from "../../../../mocks/executionTracker";
import { convertToInternationalCurrencySingleChar } from "../../../../util/helper";
const OverviewKPI: React.FC<{ data; chartSkeleton?: boolean }> = ({ data, chartSkeleton }) => {
	const [kpiData, setKpiData] = useState<IStats[]>([]);

	const formatKPIData = (response) => {
		const kpi = [...dataObject.KPIData];
		kpi[0].value = response.netRevenue.netRevenueValue ? convertToInternationalCurrencySingleChar("$", response.netRevenue.netRevenueValue) : "0";
		kpi[0].raise = Number(response.netRevenue.percentageChange) >= 0;
		kpi[0].change = response.netRevenue.percentageChange ? `${convertToInternationalCurrencySingleChar("", response.netRevenue.percentageChange)}%` : "0";
		kpi[1].value = response.profit.profitPercent ? `${convertToInternationalCurrencySingleChar("$", response.profit.profitPercent)}%` : "0";
		kpi[1].raise = Number(response.profit.percentageChange) >= 0;
		kpi[1].change = response.profit.percentageChange ? `${convertToInternationalCurrencySingleChar("", response.profit.percentageChange)}%` : "0";
		kpi[2].value = response.volume.percentageChange ? convertToInternationalCurrencySingleChar("$", response.volume.volume) : "0";
		kpi[2].raise = Number(response.volume.percentageChange) >= 0;
		kpi[2].change = response.volume.percentageChange ? `${convertToInternationalCurrencySingleChar("", response.volume.percentageChange)}%` : "0";
		setKpiData(kpi);
	};

	useEffect(() => {
		if (data) {
			formatKPIData(data);
		}
	}, [data]);
	return !chartSkeleton ? (
		<Box sx={{ background: "#005EA6", borderRadius: "5px", padding: 2 }}>
			<Grid container spacing={2} justifyContent="space-around">
				{kpiData.map((item: IStats, i) => (
					<Grid item xs={3} key={`set-1-stat-card-${i}`} display="block">
						<StatCard data={item} showSkeleton={false} />
					</Grid>
				))}
			</Grid>
		</Box>
	) : (
		<Grid item xs={12}>
			<Skeleton variant="rectangular" height={140} />
		</Grid>
	);
};
export default OverviewKPI;
