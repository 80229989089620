import { Grid } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect } from "react";
import { CircularProgressBarConfig } from "./CircularProgressBarConfig";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const CircularProgressBar: React.FC<{ data: number; chartRef }> = ({ data, chartRef }) => {
	const highchartOptions = CircularProgressBarConfig;
	highchartOptions.series[0].data.push(data);
	return (
		<Grid container>
			<Grid item xs={12} sm={12}>
				<HighchartsReact highcharts={Highcharts} options={highchartOptions} ref={chartRef} />
			</Grid>
		</Grid>
	);
};

export default CircularProgressBar;
