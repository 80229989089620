import { title } from "process";
import { ICountryData, IKeyValueConfiguration } from "../types/common";

const categories = [
	{ label: "Sav-Salty", value: "SAV-SALTY" },
	{ label: "Sweet", value: "Sweet" },
	{ label: "Yummy", value: "Yummy" },
	{ label: "Crunchy", value: "Crunchy" },
	{ label: "No Sugar", value: "No Sugar" },
	{ label: "Baked", value: "Baked" },
];

const countries: ICountryData[] = [
	{
		id: "1",
		label: "Mexico",
		value: "MEXICO",
		default: false,
		initial: "MX",
		region: "north_america_mill",
	},
	{
		id: "2",
		label: "Argentina",
		value: "ARGENTINA",
		default: false,
		initial: "AR",
		region: "south_america_mill",
	},
	{
		id: "3",
		label: "Chile",
		value: "CHILE",
		default: false,
		initial: "CL",
		region: "south_america_mill",
	},
	{
		id: "4",
		label: "Brazil",
		value: "BRAZIL",
		default: false,
		initial: "BR",
		region: "south_america_mill",
	},
	{
		id: "5",
		label: "Colombia",
		value: "COLOMBIA",
		default: false,
		initial: "CO",
		region: "south_america_mill",
	},
	{
		id: "6",
		label: "Guatemala",
		value: "GUATEMALA",
		default: false,
		initial: "GT",
		region: "north_america_mill",
	},
];
const periodicities = [
	{ id: "1", label: "Daily", value: "Daily", default: false },
	{ id: "2", label: "Weekly", value: "Weekly", default: false },
	{ id: "3", label: "Monthly", value: "Monthly", default: false },
	{ id: "4", label: "Quarterly", value: "Quarterly", default: false },
	{ id: "5", label: "Yearly", value: "Yearly", default: false },
];

const standardSortOption = [
	{ id: "1", label: "A-Z", value: "a-z" },
	{ id: "2", label: "Newest", value: "newest" },
	{ id: "3", label: "Most Popular", value: "most-popular" },
	{ id: "4", label: "Z-A", value: "z-a" },
];

const pageList = [
	{
		id: "price-architecture",
		title: "",
		value: "Price_Architecture",
		dashboardId: 7,
		label: "Pricing & PPA",
		allowCreateNotif: true,
	},
	{
		id: "api-vs-volume",
		title: "",
		value: "API_VS_Volume_Dashboard",
		dashboardId: 4,
		label: "API vs Volume",
		allowCreateNotif: true,
	},
	{
		id: "profit-parabola-dashboard",
		title: "",
		value: "Profit_Parabola",
		dashboardId: 3,
		label: "Profit Parabola",
		allowCreateNotif: false,
	},
	{
		id: "sales-by-price-point",
		title: "Sales By Price Point",
		value: "Sales_By_Price_Point",
		dashboardId: 5,
		label: "Sales by Price Point",
		allowCreateNotif: false,
	},
	{
		id: "macro-econ-dashboard",
		title: "",
		value: "Macro_Econ_Dashboard",
		dashboardId: 6,
		label: "Macro Econ",
		allowCreateNotif: false,
	},
	{
		id: "sell-out-dashboard",
		title: "",
		value: "Sell_Out_Dashboard",
		dashboardId: 9,
		label: "Sell Out",
		allowCreateNotif: false,
	},
	{
		id: "revenue-map",
		title: "",
		value: "Revenue_Map",
		dashboardId: 7,
		label: "Revenue Map",
		allowCreateNotif: true,
	},
	{
		id: "historic-price",
		title: "",
		value: "Histroric_Price",
		dashboardId: 8,
		label: "Historic Price",
		allowCreateNotif: false,
	},
	{
		id: "elasticity-pricing-track",
		title: "",
		value: "Elasticity_Pricing_Track",
		dashboardId: 10,
		label: "Elasticity Pricing Track",
		allowCreateNotif: true,
	},
	{
		id: "pricing-potential",
		title: "",
		value: "Pricing_Potential",
		dashboardId: 25,
		label: "Pricing Potential",
		allowCreateNotif: false,
	},
	{
		id: "brand-ladder",
		title: "",
		value: "Brand_Ladder",
		dashboardId: 26,
		label: "Brand Ladder",
		allowCreateNotif: false,
	},
	{
		id: "pack-economics",
		title: "",
		value: "Pack_Economics",
		dashboardId: 28,
		label: "Pack Economics",
		allowCreateNotif: false,
	},
	{
		id: "price-setting-tool",
		title: "",
		value: "Price_Setting_Tool",
		dashboardId: 30,
		label: "Price Setting Tool",
		allowCreateNotif: false,
	},
	{
		id: "price-range-piano",
		title: "",
		value: "Price_Range_Piano",
		dashboardId: 32,
		label: "Price Range Piano",
		allowCreateNotif: true,
	},
	{
		id: "price-ladder",
		title: "",
		value: "Price_Ladder",
		dashboardId: 27,
		label: "Price Ladder",
		allowCreateNotif: true,
	},
	{
		id: "promo-and-trade-management",
		title: "",
		value: "Trade_Management",
		dashboardId: null,
		label: "Promotions & Trade Management",
		allowCreateNotif: true,
	},
	{
		id: "mix-management",
		title: "",
		value: "Mix_Management",
		dashboardId: null,
		label: "Mix Management",
		allowCreateNotif: true,
	},
	{
		id: "integrated-strategy",
		title: "",
		value: "Integrated_Strategy",
		dashboardId: null,
		label: "Integrated Strategy",
		allowCreateNotif: false,
	},
	{
		id: "home",
		title: "",
		value: "Home",
		dashboardId: null,
		label: "Home",
		allowCreateNotif: true,
	},
];

const dashboardCardMock = [
	{
		name: "Profit Parabola",
		viewCount: 0,
		thumbnailUrl: "",
		route: "/profit-parabola-dashboard",
		countries: ["Mexico", "Chili"],
		type: "Predictive",
		createdOn: "2022-02-10T00:00:00",
	},
	{
		name: "API VS Volume",
		viewCount: 0,
		thumbnailUrl: "",
		route: "/api-vs-volume",
		countries: ["Mexico", "Chili"],
		type: "Predictive",
		createdOn: "2022-02-11T00:00:00",
	},
	{
		name: "EPT",
		viewCount: 0,
		thumbnailUrl: "",
		route: "/elasticity-pricing-track",
		countries: ["Mexico", "Chili"],
		type: "Predictive",
		createdOn: "2022-02-10T00:00:00",
	},
	{
		name: "HPCPE",
		viewCount: 0,
		thumbnailUrl: "",
		route: "/historic-price",
		countries: ["Mexico", "Chili"],
		type: "Predictive",
		createdOn: "2022-02-11T00:00:00",
	},
];

const pageNames = {
	home: {
		title: "Home",
	},
	createUser: {
		title: "CreateUser",
	},
	editUser: {
		title: "EditUser",
	},
	priceArchitecture: {
		title: "PriceArchitecture",
	},
	integratedStrategy: {
		title: "IntegratedStrategy",
	},
	tradeManagement: {
		title: "TradeManagement",
	},
	mixManagement: {
		title: "MixManagement",
	},
	assortmentPrioritizer: {
		title: "AssortmentPrioritizer",
	},
	assortmentTool: {
		title: "AssortmentTool",
	},
	mixOptimizer: {
		title: "MixOptimizer",
	},
	executionTracker: {
		title: "ExecutionTracker",
	},
	alertAndNotification: {
		title: "AlertAndNotification",
	},
	notificationDetail: {
		title: "NotificationDetail",
	},
	userSettings: {
		title: "UserSettings",
	},
	mmpwTest: {
		title: "MMPWTest",
	},
	userList: {
		title: "UserList",
	},
	geoXREF: {
		title: "GeoXREF",
	},
	productXREF: {
		title: "ProductXREF",
	},
	dataSourceRefresh: {
		title: "DataSourceRefresh",
	},
};

const filterOrder = [
	{
		key: "vendor",
		order: 1,
	},
	{
		key: "category",
		order: 2,
	},
	{
		key: "segment",
		order: 3,
	},
	{
		key: "brand",
		order: 4,
	},
	{
		key: "subBrand",
		order: 5,
	},
	{
		key: "packSize",
		order: 6,
	},
	{
		key: "permutation",
		order: 7,
	},
];

const monthSortOrder: IKeyValueConfiguration[] = [
	{
		label: "Jan",
		value: 1,
	},
	{
		label: "Feb",
		value: 2,
	},
	{
		label: "Mar",
		value: 3,
	},
	{
		label: "Apr",
		value: 4,
	},
	{
		label: "May",
		value: 5,
	},
	{
		label: "Jun",
		value: 6,
	},
	{
		label: "Jul",
		value: 7,
	},
	{
		label: "Aug",
		value: 8,
	},
	{
		label: "Sep",
		value: 9,
	},
	{
		label: "Oct",
		value: 10,
	},
	{
		label: "Nov",
		value: 11,
	},
	{
		label: "Dec",
		value: 12,
	},
];

export { categories, countries, standardSortOption, pageList, dashboardCardMock, pageNames, periodicities, filterOrder, monthSortOrder };
