import React, { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardContent, Grid } from "@mui/material";
import { IAssortmentPenetrationLimit, IExecutionTopFilterConfigurations, IMnAFilterConfigurations } from "../../../../types/mixAndAssortment";
import { getTimeRangeValues, sortEndTimePeriod } from "../../../../util/mnaHelper";
import {
	getExecutionTrackerCurrentAvgData,
	getExecutionTrackerGeoFilterData,
	getExecutionTrackerOverallFilterData,
} from "../../../../util/mixAndAssortmentServices";
import { toast } from "../../../../store/actions/toast.action";
import dataObject from "../../../../mocks/executionTracker";
import FilterAccordion from "../../../../components/Filters";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import DatasourceTooltip from "../../../../components/MnAFilters/DatasourceTooltip/DatasourceTooltip";
import UserInputFilters from "./UserInputFilters";
import Indicator from "../../../../components/Loader";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { monthSortOrder } from "../../../../mocks/common";

const disableFilter = (selectedFilters: IMnAFilterConfigurations, selectedPenetrationLimit: IAssortmentPenetrationLimit) => {
	let flag: boolean = true;
	if (
		selectedFilters.assortmentScenario?.length &&
		selectedFilters.businessUnit.length &&
		selectedFilters.channel.length &&
		selectedFilters.country.length &&
		selectedFilters.dateTimePeriod.length &&
		selectedFilters.mixScenario?.length &&
		selectedFilters.region.length &&
		selectedFilters.storeSegment.length &&
		selectedPenetrationLimit.mustHaveSKU > 0 &&
		selectedPenetrationLimit.goodToHaveSKU > 0
	)
		flag = false;
	return flag;
};

const TopFilters: FC<{
	selectedFilters: IMnAFilterConfigurations;
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
	selectedPenetrationLimit: IAssortmentPenetrationLimit;
	setSelectedPenetrationLimit: React.Dispatch<React.SetStateAction<IAssortmentPenetrationLimit>>;
	callBack: () => void;
	resetData: () => void;
}> = ({ selectedFilters, setSelectedFilters, selectedPenetrationLimit, setSelectedPenetrationLimit, callBack, resetData }) => {
	const dispatch = useDispatch();
	const [filterOptions, setFilterOptions] = useState<IMnAFilterConfigurations>(dataObject.overallFilterOptions);
	const [overAllFilterData, setOverAllFilterData] = useState(dataObject.overallFilters);
	const [scenarioFilterData, setScenarioFilterData] = useState(dataObject.decisionFilter);
	const [originalOverallFilterData, setOriginalOverallFilterData] = useState<IExecutionTopFilterConfigurations[]>([]);
	const [originalGeoFilterData, setOriginalGeoFilterData] = useState<IExecutionTopFilterConfigurations[]>([]);
	const [geoFilterOption, setGeoFilterOption] = useState<IMnAFilterConfigurations>(dataObject.overallFilterOptions);
	const [geoFilterData, setGeoFilterData] = useState(dataObject.geoFilter);
	const [overAllFilterSubTitle, setOverAllFilterSubTitle] = useState("");
	const [overAllFilterTitle, setOverAllFilterTitle] = useState("");
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		setShowLoader(true);
		getExecutionTrackerOverallFilterData()
			.then((response) => {
				if (response.data) {
					setOriginalOverallFilterData(response.data);
				} else if (response.error) {
					dispatch(toast("Overall Filter: " + response.error, true, 2000));
				}
			})
			.finally(() => {
				setShowLoader(false);
			});
	}, []);

	useEffect(() => {
		if (originalOverallFilterData.length > 0) {
			let countryFilterOptions: string[] = [];
			countryFilterOptions = Array.from(
				new Set(
					originalOverallFilterData.map((filterOptions) => {
						return filterOptions.country;
					})
				)
			);
			let dateTimeFilterOptions: string[] = [];
			let selectedFilterOptions = originalOverallFilterData.filter((filterOptions) => {
				return selectedFilters.country.indexOf(filterOptions.country) !== -1;
			});
			dateTimeFilterOptions = Array.from(
				new Set(
					selectedFilterOptions.map((filterOptions) => {
						return filterOptions.dateTimePeriod;
					})
				)
			);
			let buFilterOptions: string[] = [];
			selectedFilterOptions = selectedFilterOptions.filter((filterOptions) => {
				return selectedFilters.dateTimePeriod.indexOf(filterOptions.dateTimePeriod) !== -1;
			});
			buFilterOptions = Array.from(
				new Set(
					selectedFilterOptions.map((filterOptions) => {
						return filterOptions.businessUnit;
					})
				)
			);
			let endTimeFilterOptions: string[] = [];
			selectedFilterOptions = selectedFilterOptions.filter((filterOptions) => {
				return selectedFilters.businessUnit.indexOf(filterOptions.businessUnit) !== -1;
			});
			endTimeFilterOptions = Array.from(
				new Set(
					selectedFilterOptions.map((filterOptions) => {
						return filterOptions.endTimePeriod;
					})
				)
			);
			endTimeFilterOptions = sortEndTimePeriod(monthSortOrder, endTimeFilterOptions);
			setFilterOptions({
				...filterOptions,
				country: countryFilterOptions,
				businessUnit: buFilterOptions,
				dateTimePeriod: dateTimeFilterOptions,
				endTimePeriod: endTimeFilterOptions,
			});
			setOverAllFilterData({
				...overAllFilterData,
				country: { ...overAllFilterData.country },
				dateTimePeriod: { ...overAllFilterData.dateTimePeriod, disabled: !dateTimeFilterOptions || dateTimeFilterOptions.length === 0 },
				businessUnit: { ...overAllFilterData.businessUnit, disabled: !buFilterOptions || buFilterOptions.length === 0 },
				endTimePeriod: { ...overAllFilterData.endTimePeriod, disabled: !endTimeFilterOptions || endTimeFilterOptions.length === 0 },
			});
			setGeoFilterData({
				...geoFilterData,
				channel: { ...geoFilterData.channel, disabled: true },
				region: { ...geoFilterData.region, disabled: true },
				storeSegment: { ...geoFilterData.storeSegment, disabled: true },
			});
			setScenarioFilterData({
				...scenarioFilterData,
				assortmentScenario: { ...scenarioFilterData.assortmentScenario, disabled: true },
				mixScenario: { ...scenarioFilterData.mixScenario, disabled: true },
			});
		}
		if (originalGeoFilterData.length > 0 && selectedFilters.endTimePeriod[0]) {
			let channelFilterOptions: string[] = [];
			channelFilterOptions = Array.from(
				new Set(
					originalGeoFilterData.map((geoFilters) => {
						return geoFilters.channel;
					})
				)
			);
			channelFilterOptions = channelFilterOptions.sort((a: string, b: string) => {
				if (a > b) {
					return 1;
				} else {
					return -1;
				}
			});
			let regionFilterOptions: string[] = [];
			let selectedGeoFilters = originalGeoFilterData.filter((geoFilters) => {
				return selectedFilters.channel.indexOf(geoFilters.channel) !== -1;
			});
			regionFilterOptions = Array.from(
				new Set(
					selectedGeoFilters.map((geoFilters) => {
						return geoFilters.region;
					})
				)
			);
			regionFilterOptions = regionFilterOptions.sort((a: string, b: string) => {
				if (a > b) {
					return 1;
				} else {
					return -1;
				}
			});
			let storeSegmentFilterOptions: string[] = [];
			selectedGeoFilters = selectedGeoFilters.filter((geoFilters) => {
				return selectedFilters.region.indexOf(geoFilters.region) !== -1;
			});
			storeSegmentFilterOptions = Array.from(
				new Set(
					selectedGeoFilters.map((geoFilters) => {
						return geoFilters.storeSegment;
					})
				)
			);
			storeSegmentFilterOptions = storeSegmentFilterOptions.sort((a: string, b: string) => {
				if (a > b) {
					return 1;
				} else {
					return -1;
				}
			});
			setGeoFilterOption({ ...geoFilterOption, channel: channelFilterOptions, region: regionFilterOptions, storeSegment: storeSegmentFilterOptions });
			setGeoFilterData({
				...geoFilterData,
				channel: { ...geoFilterData.channel, disabled: false },
				region: { ...geoFilterData.region, disabled: !regionFilterOptions || regionFilterOptions.length === 0 },
				storeSegment: { ...geoFilterData.storeSegment, disabled: !storeSegmentFilterOptions || storeSegmentFilterOptions.length === 0 },
			});
			setScenarioFilterData({
				...scenarioFilterData,
				assortmentScenario: {
					...scenarioFilterData.assortmentScenario,
					disabled: selectedFilters.channel.length && selectedFilters.region.length && selectedFilters.storeSegment.length ? false : true,
				},
				mixScenario: {
					...scenarioFilterData.mixScenario,
					disabled: selectedFilters.channel.length && selectedFilters.region.length && selectedFilters.storeSegment.length ? false : true,
				},
			});
		}
	}, [originalOverallFilterData, selectedFilters, originalGeoFilterData]);

	useEffect(() => {
		if (selectedFilters.endTimePeriod[0]) {
			setOverAllFilterSubTitle(getTimeRangeValues(monthSortOrder, selectedFilters.endTimePeriod[0], selectedFilters.dateTimePeriod[0]));
			setOverAllFilterTitle("Overall Filters:");
			setShowLoader(true);
			getExecutionTrackerGeoFilterData(selectedFilters)
				.then((response) => {
					if (response && response.data) {
						setOriginalGeoFilterData(response.data);
					} else if (response && response.error) {
						dispatch(toast("Geo Filters: " + response.error, true, 2000));
					}
				})
				.finally(() => {
					setShowLoader(false);
				});
		} else {
			setOverAllFilterTitle("Overall Filters");
			setOverAllFilterSubTitle("");
		}
	}, [selectedFilters.endTimePeriod]);

	useEffect(() => {
		if (selectedFilters.storeSegment.length && selectedFilters.assortmentScenario?.length && selectedFilters.mixScenario?.length) {
			setShowLoader(true);
			getExecutionTrackerCurrentAvgData(selectedFilters)
				.then((response) => {
					if (response && response.data) {
						setSelectedPenetrationLimit({
							mustHaveSKU: response.data.mustHaveSKU * 100,
							mustHaveSkuCurrentAverage: response.data.mustHaveSkuCurrentAverage * 100,
							goodToHaveSKU: response.data.goodToHaveSKU * 100,
							goodToHaveSkuCurrentAverage: response.data.goodToHaveSkuCurrentAverage * 100,
						});
					} else if (response && response.error) {
						dispatch(toast("Current Average Data: " + response.error, true, 2000));
					}
				})
				.finally(() => {
					setShowLoader(false);
				});
		}
	}, [selectedFilters.assortmentScenario, selectedFilters.storeSegment, selectedFilters.mixScenario]);

	useEffect(() => {
		let isFilterChangeFlag: boolean = false;
		const defaultSelectedFilters: IMnAFilterConfigurations = { ...selectedFilters };
		if (selectedFilters.country.length === 0 && filterOptions.country[0]) {
			defaultSelectedFilters.country = [filterOptions.country[0]];
			isFilterChangeFlag = true;
		}
		if (isFilterChangeFlag) {
			setSelectedFilters({ ...defaultSelectedFilters });
		}
	}, [filterOptions, selectedFilters]);

	const onChangeFilter = (key, value) => {
		let selectedValues = { ...selectedFilters };
		switch (key) {
			case "country": {
				selectedValues = {
					...selectedFilters,
					country: value,
					dateTimePeriod: [],
					businessUnit: [],
					endTimePeriod: [],
					channel: [],
					region: [],
					storeSegment: [],
					assortmentScenario: [],
					mixScenario: [],
				};
				break;
			}
			case "dateTimePeriod": {
				selectedValues = {
					...selectedFilters,
					dateTimePeriod: value,
					businessUnit: [],
					endTimePeriod: [],
					channel: [],
					region: [],
					storeSegment: [],
					assortmentScenario: [],
					mixScenario: [],
				};
				break;
			}
			case "businessUnit": {
				selectedValues = {
					...selectedFilters,
					businessUnit: value,
					endTimePeriod: [],
					channel: [],
					region: [],
					storeSegment: [],
					assortmentScenario: [],
					mixScenario: [],
				};
				break;
			}
			case "endTimePeriod": {
				selectedValues = { ...selectedFilters, endTimePeriod: value, channel: [], region: [], storeSegment: [], assortmentScenario: [], mixScenario: [] };
				break;
			}
			case "channel": {
				selectedValues = { ...selectedFilters, channel: value, region: [], storeSegment: [], assortmentScenario: [], mixScenario: [] };
				break;
			}
			case "region": {
				selectedValues = { ...selectedFilters, region: value, storeSegment: [], assortmentScenario: [], mixScenario: [] };
				break;
			}
			case "storeSegment": {
				selectedValues = { ...selectedFilters, storeSegment: value, assortmentScenario: [], mixScenario: [] };
				break;
			}
			case "assortmentScenario": {
				selectedValues = { ...selectedFilters, assortmentScenario: value };
				break;
			}
			case "mixScenario": {
				selectedValues = { ...selectedFilters, mixScenario: value };
				break;
			}
		}
		setSelectedFilters(selectedValues);
	};

	const clearFilter = () => {
		setSelectedFilters({
			country: [],
			dateTimePeriod: [],
			businessUnit: [],
			endTimePeriod: [],
			region: [],
			channel: [],
			storeSegment: [],
			mixScenario: [],
			assortmentScenario: [],
			portfolioGeo: {
				level: "Brand",
				channel: [],
				region: [],
				storeSegment: [],
			},
			geoSummary: {
				level: "Store Segment",
				segment: [],
				brand: [],
				sku: [],
			},
		});
		setSelectedPenetrationLimit({
			mustHaveSKU: 0,
			mustHaveSkuCurrentAverage: 0,
			goodToHaveSKU: 0,
			goodToHaveSkuCurrentAverage: 0,
		});
		resetData();
	};

	return (
		<Card className="m-b-20" style={{ position: "relative" }}>
			<Indicator position="absolute" show={showLoader} />
			<CardContent>
				<>
					<DatasourceTooltip dashboardName="Execution" />
					<Grid marginTop={3}>
						<FilterAccordion title={overAllFilterTitle} expandFlag={true} subTitle={overAllFilterSubTitle}>
							<Grid container>
								<Grid item sm={9} className="geo_filters_left">
									<CommonMnAFilters
										filterData={filterOptions}
										onChange={onChangeFilter}
										data={overAllFilterData}
										defaultFilters={selectedFilters ?? null}
										filterOrder={dataObject.analysisFilterOrder}
									/>
								</Grid>
							</Grid>
						</FilterAccordion>
						<FilterAccordion title="Geo Filters" expandFlag={true}>
							<Grid container>
								<Grid item sm={9} className="geo_filters_left">
									<CommonMnAFilters
										filterData={geoFilterOption}
										onChange={onChangeFilter}
										data={geoFilterData}
										showSkeleton={false}
										defaultFilters={selectedFilters ?? null}
										filterOrder={dataObject.geoFilterOrder}
									/>
								</Grid>
							</Grid>
						</FilterAccordion>
						<UserInputFilters
							selectedFilters={selectedFilters}
							onChangeFilter={onChangeFilter}
							selectedPenetrationLimit={selectedPenetrationLimit}
							setSelectedPenetrationLimit={setSelectedPenetrationLimit}
							scenarioFilterData={scenarioFilterData}
							setLoader={setShowLoader}
						/>
					</Grid>
					<Grid className="p-l-16" marginTop={2}>
						<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
							Clear Filter
						</OrangeBtn>
						<PrimaryBtn color="primary" onClick={callBack} disabled={disableFilter(selectedFilters, selectedPenetrationLimit)}>
							Run Simulation
						</PrimaryBtn>
					</Grid>
				</>
			</CardContent>
		</Card>
	);
};

export default TopFilters;
