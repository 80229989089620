import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import FilterAccordion from "../../../../components/Filters";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import dataObject from "../../../../mocks/executionTracker";
import {
	IAssortmentPenetrationLimit,
	IExecutionUserInputFilterOptionResponse,
	IExecutionUserINputMixOptionResponse,
	IMnAFilterConfigurations,
} from "../../../../types/mixAndAssortment";
import { getExecutionTrackerUserInputData } from "../../../../util/mixAndAssortmentServices";
import { toast } from "../../../../store/actions/toast.action";
import { useDispatch } from "react-redux";
import TextFieldNumber from "../../../../components/TextFieldNumber/TextFieldNumber";
import { getTextWidth } from "../../../../util/helper";

const UserInputFilters: FC<{
	selectedFilters: IMnAFilterConfigurations;
	onChangeFilter;
	selectedPenetrationLimit: IAssortmentPenetrationLimit;
	setSelectedPenetrationLimit: Dispatch<SetStateAction<IAssortmentPenetrationLimit>>;
	scenarioFilterData;
	setLoader: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ selectedFilters, onChangeFilter, selectedPenetrationLimit, setSelectedPenetrationLimit, scenarioFilterData, setLoader }) => {
	const dispatch = useDispatch();
	const [originalUserInputFilterOption, setOriginalUserInputFilterOption] = useState<IExecutionUserInputFilterOptionResponse>({
		assortmentScenarios: [],
		mixScenarios: [],
	});
	const [userInputFilterOption, setUserInputFilterOption] = useState<IMnAFilterConfigurations>(dataObject.overallFilterOptions);
	const [mixScenarioDetail, setMixScenarioDetail] = useState<IExecutionUserINputMixOptionResponse>({
		scenarioName: "",
		channel: "",
		region: "",
		storeSegment: "",
	});

	useEffect(() => {
		if (selectedFilters.storeSegment.length) {
			setLoader(true);
			getExecutionTrackerUserInputData(selectedFilters)
				.then((response) => {
					if (response && response.data) {
						setOriginalUserInputFilterOption(response.data);
					} else if (response && response.error) {
						dispatch(toast("User Scenario Execution: " + response.error, true, 2000));
					}
				})
				.finally(() => {
					setLoader(false);
				});
		}
	}, [selectedFilters.storeSegment]);

	useEffect(() => {
		let assortmentFilterOption: string[] = [];
		let mixFilterOptions: string[] = [];
		if (originalUserInputFilterOption.assortmentScenarios.length > 0) {
			assortmentFilterOption = originalUserInputFilterOption.assortmentScenarios;
		}
		if (originalUserInputFilterOption.mixScenarios.length > 0) {
			mixFilterOptions = Array.from(
				new Set(
					originalUserInputFilterOption.mixScenarios.map((mixFilters) => {
						return mixFilters.scenarioName;
					})
				)
			);
		}

		setUserInputFilterOption({ ...userInputFilterOption, assortmentScenario: assortmentFilterOption, mixScenario: mixFilterOptions });
	}, [originalUserInputFilterOption]);

	useEffect(() => {
		if (selectedFilters.mixScenario) {
			const mixFilteredData = originalUserInputFilterOption.mixScenarios.filter((mixFilters) => {
				return selectedFilters.mixScenario && mixFilters.scenarioName === selectedFilters.mixScenario[0];
			});

			const mixChannelFilterOptions = Array.from(
				new Set(
					mixFilteredData.map((mixFilters) => {
						return mixFilters.channel;
					})
				)
			);
			const mixRegionFilterOptions = Array.from(
				new Set(
					mixFilteredData.map((mixFilters) => {
						return mixFilters.region;
					})
				)
			);
			const mixStoreSegmentFilterOptions = Array.from(
				new Set(
					mixFilteredData.map((mixFilters) => {
						return mixFilters.storeSegment;
					})
				)
			);
			setUserInputFilterOption({
				...userInputFilterOption,
				portfolioGeo: {
					channel: mixChannelFilterOptions,
					region: mixRegionFilterOptions,
					storeSegment: mixStoreSegmentFilterOptions,
					level: "",
				},
			});
			setMixScenarioDetail({
				scenarioName: selectedFilters.mixScenario[0],
				channel: mixChannelFilterOptions[0],
				region: mixRegionFilterOptions[0],
				storeSegment: mixStoreSegmentFilterOptions[0],
			});
		}
	}, [selectedFilters.mixScenario]);

	useEffect(() => {
		if (selectedFilters.assortmentScenario?.length === 0 && selectedFilters.mixScenario?.length === 0) {
			setSelectedPenetrationLimit({
				...selectedPenetrationLimit,
				mustHaveSKU: 0,
				goodToHaveSKU: 0,
				mustHaveSkuCurrentAverage: 0,
				goodToHaveSkuCurrentAverage: 0,
			});
		}
	}, [selectedFilters]);

	return (
		<FilterAccordion title="User Inputs" expandFlag={true}>
			<Grid container>
				<Grid item sm={9} className="geo_filters_left">
					<Typography color="#7E7E7E" fontWeight={600} marginBottom={2}>
						Decision Variables
					</Typography>
					<CommonMnAFilters
						filterData={userInputFilterOption}
						onChange={onChangeFilter}
						data={scenarioFilterData}
						defaultFilters={selectedFilters ?? null}
						filterOrder={dataObject.decisionFilterOrder}
					/>
					{selectedFilters.mixScenario?.length ? (
						<Box marginBottom={3}>
							<Grid item>
								<Typography fontSize={14} color="#7E7E7E" fontWeight={600} marginBottom={1}>
									Additional Scenario Details
								</Typography>
							</Grid>
							<Grid item marginBottom={1}>
								<Typography color="#000000" fontSize={14} fontWeight={600}>
									Channel
								</Typography>
								<Typography color="#000000">{mixScenarioDetail.channel}</Typography>
							</Grid>
							<Grid item marginBottom={1}>
								<Typography color="#000000" fontSize={14} fontWeight={600}>
									Region
								</Typography>
								{mixScenarioDetail.region ? <Typography color="#000000">{JSON.parse(mixScenarioDetail.region).join(", ")}</Typography> : ""}
							</Grid>
							<Grid item marginBottom={1} maxHeight={100}>
								<Typography color="#000000" fontSize={14} fontWeight={600}>
									Store Segment
								</Typography>
								{mixScenarioDetail.storeSegment ? <Typography color="#000000">{JSON.parse(mixScenarioDetail.storeSegment).join(", ")}</Typography> : ""}
							</Grid>
						</Box>
					) : null}
				</Grid>
			</Grid>
			<Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2}>
				<Typography color="#7E7E7E" fontWeight={600}>
					Assortment Targets (in %)
				</Typography>
				<Stack direction="row" spacing={1}>
					<Typography fontWeight={600} fontSize="12px" color="#000000">
						*NOTE:
					</Typography>
					<Typography color="#7E7E7E" fontSize="12px">
						Assigned Penetration (Distribution) Target can Individually be 0-100%
					</Typography>
				</Stack>
			</Stack>
			<Grid container spacing={2} className="geo_filters_left" columns={12}>
				<Grid item display="flex" xs={12}>
					<Grid item sm={2} display="flex" alignItems="center">
						<Grid item sm={8}>
							<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
								Must have SKU*
							</Typography>
						</Grid>
						<Grid item sm={4}>
							<Box display="flex" alignItems="center">
								<TextFieldNumber
									defaultValue={selectedPenetrationLimit.mustHaveSKU}
									callback={(value) => {
										setSelectedPenetrationLimit({ ...selectedPenetrationLimit, mustHaveSKU: parseFloat(value) });
									}}
								/>
								{selectedPenetrationLimit.mustHaveSKU !== null && (
									<Box component={"span"} style={{ marginLeft: getTextWidth(selectedPenetrationLimit.mustHaveSKU) + 15, position: "absolute" }}>
										%
									</Box>
								)}
							</Box>
						</Grid>
					</Grid>
					<Grid item display="flex" alignItems="center" sm={2} sx={{ marginLeft: "50px" }}>
						<Grid item sm={8}>
							<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
								Good to have SKU*
							</Typography>
						</Grid>
						<Grid item sm={4}>
							<Box display="flex" alignItems="center">
								<TextFieldNumber
									defaultValue={selectedPenetrationLimit.goodToHaveSKU}
									callback={(value) => {
										setSelectedPenetrationLimit({ ...selectedPenetrationLimit, goodToHaveSKU: parseFloat(value) });
									}}
								/>
								{selectedPenetrationLimit.goodToHaveSKU !== null && (
									<Box component={"span"} style={{ marginLeft: getTextWidth(selectedPenetrationLimit.goodToHaveSKU) + 15, position: "absolute" }}>
										%
									</Box>
								)}
							</Box>
						</Grid>
					</Grid>
				</Grid>
				<Grid item display="flex" xs={12}>
					<Grid item sm={2} display="flex" alignItems="center">
						<Grid item sm={8}>
							<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
								Current Average
							</Typography>
						</Grid>
						<Grid item sm={4}>
							<Box display="flex" alignItems="center">
								<TextFieldNumber
									defaultValue={selectedPenetrationLimit.mustHaveSkuCurrentAverage}
									callback={(value) => {
										setSelectedPenetrationLimit({ ...selectedPenetrationLimit, goodToHaveSKU: parseFloat(value) });
									}}
									disabled={true}
								/>
								{selectedPenetrationLimit.mustHaveSkuCurrentAverage !== null && (
									<Box
										component={"span"}
										style={{
											marginLeft: getTextWidth(selectedPenetrationLimit.mustHaveSkuCurrentAverage) + 15,
											position: "absolute",
											color: "rgba(0, 0, 0, 0.38)",
										}}
									>
										%
									</Box>
								)}
							</Box>
						</Grid>
					</Grid>
					<Grid item display="flex" alignItems="center" sm={2} sx={{ marginLeft: "50px" }}>
						<Grid item sm={8}>
							<Typography color="#0B0E1E" fontSize={13} fontWeight={600}>
								Current Average
							</Typography>
						</Grid>
						<Grid item sm={4}>
							<Box display="flex" alignItems="center">
								<TextFieldNumber
									defaultValue={selectedPenetrationLimit.goodToHaveSkuCurrentAverage}
									callback={(value) => {
										setSelectedPenetrationLimit({ ...selectedPenetrationLimit, goodToHaveSkuCurrentAverage: parseFloat(value) });
									}}
									disabled={true}
								/>
								{selectedPenetrationLimit.goodToHaveSkuCurrentAverage !== null && (
									<Box
										component={"span"}
										style={{
											marginLeft: getTextWidth(selectedPenetrationLimit.goodToHaveSkuCurrentAverage) + 15,
											position: "absolute",
											color: "rgba(0, 0, 0, 0.38)",
										}}
									>
										%
									</Box>
								)}
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</FilterAccordion>
	);
};

export default UserInputFilters;
