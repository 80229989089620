import { graphqlAPI } from "../store/api-constants";
import {
	IAssortmentEditScenarioPayload,
	IAssortmentListPayload,
	IAssortmentScenarioDetailObject,
	IExecutionGeoSummaryData,
	IExecutionPortfolioTableData,
	IExecutionTrackerPayload,
	IMixConstraintData,
	IMixGeoFilterOptions,
	IMixOverallFilterOptions,
	IMixPortfolioAndGeoData,
	IMixSelectedFilters,
	IMixSummaryData,
	IMnAFilterConfigurations,
} from "../types/mixAndAssortment";
import { API } from "./helper";
import {
	EDIT_SCENARIO_QUERY,
	FETCH_ASSORTMENT_AVG_DATA_QUERY,
	FETCH_ASSORTMENT_LIST_DATA_QUERY,
	FETCH_FULL_ASSORTMENT_SIMULATIONDATA_QUERY,
	FETCH_SCENARIO_DETAILS_QUERY,
} from "./queries/mnaQueries/assortment";
import { DELETE_SCENARIO_QUERY, GET_SCENARIO_LIBRARY_QUERY, MNA_DATASOURCE_QUERY, MNA_PRODUCT_FILTER_QUERY } from "./queries/mnaQueries/common";
import {
	GET_EXECUTION_TRACKER_CURRENT_AVG_QUERY,
	GET_EXECUTION_TRACKER_GEO_FILTER_QUERY,
	GET_EXECUTION_TRACKER_GEO_LEVEL_FILTER,
	GET_EXECUTION_TRACKER_GEO_LEVEL_TABLEDATA_QUERY,
	GET_EXECUTION_TRACKER_OPPORTUNITYDATA_QUERY,
	GET_EXECUTION_TRACKER_OVERALL_FILTER_QUERY,
	GET_EXECUTION_TRACKER_OVERVIEWKPI_QUERY,
	GET_EXECUTION_TRACKER_PORTFOLIO_TABLEDATA_QUERY,
	GET_EXECUTION_TRACKER_SIMULATIONDATA_QUERY,
	GET_EXECUTION_TRACKER_USERINPUT_QUERY,
} from "./queries/mnaQueries/executionTracker";
import {
	CHECK_MIX_SCENARIO,
	CHECK_UPLOADED_MIX_CONSTRAINTS_QUERY,
	GET_MIX_ASSORTMENT_SCENARIOS_OPTIONS_QUERY,
	GET_MIX_CONSTRAINTS_FOR_SCENARIO,
	GET_MIX_CONSTRAINTS_QUERY,
	GET_MIX_GEO_FILTER_DATA_QUERY,
	GET_MIX_OVERALL_FILTER_DATA_QUERY,
	GET_MIX_PORTFOLIO_AND_GEO_DATA_QUERY,
	GET_MIX_SCENARIO_DOWNLOAD_DATA_QUERY,
	GET_MIX_SELECTED_FILTERS,
	GET_MIX_SUMMARY_DATA_QUERY,
	SAVE_MIX_SCENARIO,
} from "./queries/mnaQueries/mixOptimizer";

// Common Services
export const getScenarios = async (dashboardName: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = GET_SCENARIO_LIBRARY_QUERY(dashboardName);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data && response.data.data && response.data.data.getModelScenarioLibrary) {
				data = response.data.data.getModelScenarioLibrary;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

export const deleteScenarios = async (scenarioName: string, dashboardName: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = DELETE_SCENARIO_QUERY(scenarioName, dashboardName);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.deleteScenario;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

export const getDataSources = async (dashboardName: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = MNA_DATASOURCE_QUERY(dashboardName);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.getDashboardDataSources;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

// Assortment Services

export const getAssortmentOverallFilters = async (dashboardName: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	let queryParameter = MNA_PRODUCT_FILTER_QUERY(dashboardName);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = [];
		let error = "";
		if (response && response.data) {
			data = response.data.data.getAssortmentProductFilter;
		}
		if (response && response.data.errors) {
			error = response.data.errors[0].message;
		}
		return { data: data, error: error };
	});
};

export const editAssortmentDetails = async (payload: IAssortmentEditScenarioPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		const doubleQuotesRegEx: RegExp = /\"/gi;
		payload.userInputs = payload.userInputs.replace(doubleQuotesRegEx, '\\"');
		let queryParameter = EDIT_SCENARIO_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			let message = "";
			if (response && response.data) {
				data = response.data.data.editAssortmentRunSimulation;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

export const fetchAssortmentDetails = async (scenarioName: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = FETCH_SCENARIO_DETAILS_QUERY(scenarioName);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data: IAssortmentScenarioDetailObject[] = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.getAssortmentScenarioDetails;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}

			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

export const fetchFullAssortmentSimulationData = async (scenarioName: string) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = FETCH_FULL_ASSORTMENT_SIMULATIONDATA_QUERY(scenarioName);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			let message = "";
			if (response && response.data) {
				data = response.data.data.getDownloadAssortmentSimulationData.data;
				message = response.data.data.getDownloadAssortmentSimulationData.message;
				error = response.data.data.getDownloadAssortmentSimulationData.error;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error, message: message };
		});
	} catch (e) {
		return e;
	}
};

export const fetchAssortmentAverageData = async (payload: IAssortmentListPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = FETCH_ASSORTMENT_AVG_DATA_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			if (response && response.data) {
				data = response.data.data.getAssortmentCurrentAverage;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error };
		});
	} catch (e) {
		return e;
	}
};

export const fetchFullAssortmentListData = async (payload: IAssortmentListPayload) => {
	try {
		const graphQLApiUrl: string = await graphqlAPI();
		let queryParameter = FETCH_ASSORTMENT_LIST_DATA_QUERY(payload);
		return await API.post(graphQLApiUrl, queryParameter).then((response) => {
			let data = [];
			let error = "";
			let message = "";
			if (response && response.data) {
				data = response.data.data.getDownloadAssortmenrListTable.data;
				message = response.data.data.getDownloadAssortmenrListTable.message;
			}
			if (response && response.data.errors) {
				error = response.data.errors[0].message;
			}
			return { data: data, error: error, message: message };
		});
	} catch (e) {
		return e;
	}
};

// Mix Management Services
const generateUserInputs = (selectedFilters: IMixSelectedFilters) => {
	const userInputs = {
		level: selectedFilters.level ?? "",
		channel: selectedFilters.channel ?? "",
		region: selectedFilters.region ?? [],
		storeSegment: selectedFilters.storeSegment ?? [],
		assortmentScenario: selectedFilters.assortmentScenario ?? [],
	};
	return JSON.stringify(userInputs);
};

export const getMixOverallFilters = async () => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_OVERALL_FILTER_DATA_QUERY;
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IMixOverallFilterOptions | undefined;
		let error = "";
		if (response?.data?.data) data = response.data.data.getFilterData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getMixGeoFilters = async (country: string, dateTimePeriod: string, businessUnit: string, endTimePeriod: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_GEO_FILTER_DATA_QUERY(country, dateTimePeriod, businessUnit, endTimePeriod);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IMixGeoFilterOptions | undefined;
		let error = "";
		if (response?.data?.data) data = response.data.data.getMixGeoFilterData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getMixAssortmentScenarios = async (country: string, businessUnit: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_ASSORTMENT_SCENARIOS_OPTIONS_QUERY(country, businessUnit);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: { key: string; value: string }[] = [];
		let error = "";
		if (response?.data?.data)
			data = response.data.data.getAssortmentScenarios.map((scenario) => ({ key: scenario.scenarioName, value: scenario.scenarioName }));
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getMixConstraints = async (selectedFilters: IMixSelectedFilters) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_CONSTRAINTS_QUERY(selectedFilters, generateUserInputs(selectedFilters));
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IMixConstraintData[] = [];
		let error = "";
		if (response?.data?.data) data = response.data.data.getMixConstraints;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const checkUploadedMixConstraints = async (selectedFilters: IMixSelectedFilters, mixConstraints: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = CHECK_UPLOADED_MIX_CONSTRAINTS_QUERY(selectedFilters, generateUserInputs(selectedFilters), mixConstraints);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = { success: false };
		let error = "";
		if (response?.data?.data) data = response.data.data.getDownloadMixConstraints;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const saveMixScenario = async (
	scenarioName: string,
	isNewScenario: boolean,
	bypassDuplicateCheck: boolean,
	userFullName: string,
	selectedFilters: IMixSelectedFilters,
	mixConstraints: IMixConstraintData[]
) => {
	const graphQLApiUrl: string = await graphqlAPI();
	// Remove id from mixConstraints and convert minMix and maxMix to string
	const convertedMixConstraints = mixConstraints.map((constraint) => {
		const { id, ...rest } = constraint;
		return { ...rest, minMix: rest.minMix.toString(), maxMix: rest.maxMix.toString() };
	});
	const queryParameter = SAVE_MIX_SCENARIO(
		scenarioName,
		isNewScenario,
		bypassDuplicateCheck,
		userFullName,
		selectedFilters,
		generateUserInputs(selectedFilters),
		JSON.stringify(convertedMixConstraints)
	);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = { success: false, duplicate: false, message: "" };
		let error = "";
		if (response?.data?.data) data = response.data.data.editMixScenario;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const checkMixScenario = async (scenarioName: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = CHECK_MIX_SCENARIO(scenarioName);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = { isExpired: true, isInProgress: true };
		let error = "";
		if (response?.data?.data) data = response.data.data.viewScenario;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getMixSelectedFilters = async (scenarioName: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_SELECTED_FILTERS(scenarioName);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IMixSelectedFilters | undefined;
		let error = "";
		if (response?.data?.data) {
			const respData = response.data.data.getScenarioDetails;
			const userInputs = JSON.parse(respData?.userInputs);
			data = {
				country: respData?.country ?? "",
				dateTimePeriod: respData?.dateTimePeriod ?? "",
				businessUnit: respData?.businessUnit ?? "",
				endTimePeriod: respData?.endTimePeriod ?? "",
				level: userInputs?.level ?? "",
				channel: userInputs?.channel ?? "",
				region: userInputs?.region ?? [],
				storeSegment: userInputs?.storeSegment ?? [],
				goal: respData?.goal ?? "",
				assortmentScenario: userInputs?.assortmentScenario ?? "",
			};
		}
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getMixConstraintsForScenario = async (scenarioName: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_CONSTRAINTS_FOR_SCENARIO(scenarioName);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IMixConstraintData[] = [];
		let error = "";
		if (response?.data?.data) data = response.data.data.getMixEditConstraints;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getMixSummaryData = async (scenarioName: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_SUMMARY_DATA_QUERY(scenarioName);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IMixSummaryData | undefined;
		let error = "";
		if (response?.data?.data) data = response.data.data.getSummaryData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getMixPortfolioAndGeoData = async (scenarioName: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_PORTFOLIO_AND_GEO_DATA_QUERY(scenarioName);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IMixPortfolioAndGeoData[] = [];
		let error = "";
		if (response?.data?.data) data = response.data.data.getMixPortfolioData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getMixScenarioDownloadData = async (scenarioName: string) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_MIX_SCENARIO_DOWNLOAD_DATA_QUERY(scenarioName);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = { success: false, message: "", data: "" };
		let error = "";
		if (response?.data?.data) data = response.data.data.getDownloadMixSummaryData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

/*--Execution Tracker Services--*/

export const getExecutionTrackerOverallFilterData = async () => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_OVERALL_FILTER_QUERY();
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = [];
		let error = "";
		if (response && response.data?.data) data = response.data.data.getExecutionInputFilterData;
		if (response && response.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerGeoFilterData = async (payload: IMnAFilterConfigurations) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_GEO_FILTER_QUERY(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = [];
		let error = "";
		if (response && response.data?.data) data = response.data.data.getExecutionGeoFilterData;
		if (response && response.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerUserInputData = async (payload: IMnAFilterConfigurations) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_USERINPUT_QUERY(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = null;
		let error = "";
		if (response?.data?.data) data = response.data.data.getUserScenariosExecution;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerCurrentAvgData = async (payload: IMnAFilterConfigurations) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_CURRENT_AVG_QUERY(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data;
		let error = "";
		if (response?.data?.data) data = response.data.data.getExecutionCurrentAverage;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerSimulationData = async (payload: IExecutionTrackerPayload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_SIMULATIONDATA_QUERY(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = null;
		let error = "";
		if (response?.data?.data) data = response.data.data.getExecutionTrackerSimulationData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerOpportunityData = async (payload: IExecutionTrackerPayload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_OPPORTUNITYDATA_QUERY(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = null;
		let error = "";
		if (response?.data?.data) data = response.data.data.getExecutionOpportunityData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerOverviewKPIData = async (payload: IExecutionTrackerPayload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_OVERVIEWKPI_QUERY(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = null;
		let error = "";
		if (response?.data?.data) data = response.data.data.getExecutionOverviewKpiData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerPortfolioTableData = async (payload: IExecutionTrackerPayload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_PORTFOLIO_TABLEDATA_QUERY(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IExecutionPortfolioTableData[] = [];
		let error = "";
		if (response?.data?.data) data = response.data.data.getExecutionPortfolioTableData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerGeoLevelTableData = async (payload: IExecutionTrackerPayload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_GEO_LEVEL_TABLEDATA_QUERY(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data: IExecutionGeoSummaryData[] = [];
		let error = "";
		if (response?.data?.data) data = response.data.data.getExecutionGeoLevelSummaryTableData;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

export const getExecutionTrackerGeoLevelFilterData = async (payload: IExecutionTrackerPayload) => {
	const graphQLApiUrl: string = await graphqlAPI();
	const queryParameter = GET_EXECUTION_TRACKER_GEO_LEVEL_FILTER(payload);
	return await API.post(graphQLApiUrl, queryParameter).then((response) => {
		let data = [];
		let error = "";
		if (response?.data?.data) data = response.data.data.getPortfolioGeoLevelFilter;
		if (response?.data?.errors) error = response.data.errors[0].message;
		return { data: data, error: error };
	});
};

// Common functions
export const convertToCsv = (jsonData: any[]) => {
	const header = Object.keys(jsonData[0]).join(",");
	const rows = jsonData.map((obj) => Object.values(obj).join(","));
	return `${header}\n${rows.join("\n")}`;
};

export const convertToCsvWithKeys = (data: any[], selectedKeys: string[], columns: { headerName: string; field: string }[]) => {
	const header = selectedKeys
		.map((key) => {
			const column = columns.find((column) => column.field === key);
			return column ? column.headerName : key;
		})
		.join(",");
	const rows = data.map((row) => selectedKeys.map((key) => row[key]).join(","));
	return `${header}\n${rows.join("\n")}`;
};

export const downloadInCsvFormat = (csvData: string, fileName: string) => {
	const file = new Blob([csvData], { type: "text/csv" });
	const link = document.createElement("a");
	link.href = URL.createObjectURL(file);
	link.download = `${fileName}.csv`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const convertCsvToJsonWithKeys = (csvData: string, columns: { headerName: string; field: string }[]) => {
	const lines = csvData.replace(/\r\n/g, "\n").split("\n");
	const result: any[] = [];
	const headers = lines[0].split(",");
	const fields = headers.map((header) => {
		const column = columns.find((column) => column.headerName === header);
		return column ? column.field : header;
	});
	lines.forEach((line, index) => {
		if (index === 0) return;
		const obj = {};
		const currentline = line.split(",");
		if (fields.length === currentline.length) {
			fields.forEach((fields, index) => {
				obj[fields] = currentline[index];
			});
			result.push(obj);
		}
	});
	return result;
};
