import React, { FC, useEffect, useState } from "react";
import { Box, Grid, IconButton, Stack } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import { format } from "date-fns";
import { completeStatusValue, inProgressStatusValue } from "../../mocks/constants";
import { IScenarioDetails } from "../../types/mixAndAssortment";
import ConfirmationPopUp from "../ConfirmationPopUp/ConfirmationPopUp";
import Indicator from "../Loader";

const useStyles = makeStyles(() => ({
	scenarioTable: {
		border: 0,
		"& .MuiDataGrid-columnHeaders": {
			background: "transparent !important",
			borderBottom: "2px solid #DFDFDF",
		},
		"& .MuiDataGrid-columnHeaderTitleContainer": {
			justifyContent: "center",
		},
		"& .MuiDataGrid-columnHeaderTitle": {
			fontWeight: 600,
		},
		"& .MuiDataGrid-columnSeparator": {
			display: "none",
		},
		"& .MuiDataGrid-cell": {
			border: 0,
		},
	},
	tableRow: {
		borderBottom: "1px solid #DFDFDF",
	},
}));

const OFFSET = 20;

const ScenarioList: FC<{
	data: IScenarioDetails[];
	onView: (scenario: string) => void;
	onEdit: (scenario: string) => void;
	deleteScenario: (scenarioName: string) => void;
	loader: boolean;
}> = ({ data, onView, onEdit, deleteScenario, loader }) => {
	const classes = useStyles();
	const [pageNo, setPageNo] = useState(1);
	const [pageSize, setPageSize] = useState(OFFSET);
	const [showDeletePopup, setShowDeletePopup] = useState(false);
	const [scenarioToBeDeleted, setScenarioToBeDeleted] = useState<string>("");
	const [recordCount, setRecordCount] = useState<number>(0);
	const [scenarioTableData, setScenarioTableData] = useState<IScenarioDetails[]>([]);
	const [gridRowHeight, setGridRowHeight] = useState(window.innerWidth < 1320 ? 80 : 60);
	const noRowsOverlay = () => (
		<Stack height="100%" alignItems="center" justifyContent="center">
			No scenarios available
		</Stack>
	);

	useEffect(() => {
		const dynamicRowHeight: number = window.innerWidth < 1320 ? 80 : 60;
		setGridRowHeight(dynamicRowHeight);
	}, [window.innerWidth]);

	const onDelete = (scenarioName: string) => {
		setShowDeletePopup(true);
		setScenarioToBeDeleted(scenarioName);
	};

	const onDeleteConfirmation = (confirmationFlag: boolean) => {
		setShowDeletePopup(false);
		if (confirmationFlag && scenarioToBeDeleted) {
			deleteScenario(scenarioToBeDeleted);
		}
		setScenarioToBeDeleted("");
	};

	const columns: GridColDef[] = [
		{
			field: "scenarioName",
			headerName: "Scenario Name",
			sortable: false,
			align: "center",
			flex: 1,
			renderCell: (params) => (
				<Box
					sx={{
						textAlign: "center",
						whiteSpace: "normal", // Allow text to wrap
						wordBreak: "break-word", // Break long words
					}}
				>
					{params.value}
				</Box>
			),
		},
		{
			field: "createdTimeStamp",
			headerName: "Created on",
			sortable: false,
			flex: 1,
			align: "center",
			renderCell: (params) => (
				<Box
					sx={{
						textAlign: "center",
						whiteSpace: "normal", // Allow text to wrap
						wordBreak: "break-word", // Break long words
					}}
				>
					{format(new Date(params.value), "dd MMM yyyy HH:mm")}
				</Box>
			),
		},
		{
			field: "createdBy",
			headerName: "Created by",
			sortable: false,
			flex: 1,
			align: "center",
			renderCell: (params) => (
				<Box
					sx={{
						textAlign: "center",
						whiteSpace: "normal", // Allow text to wrap
						wordBreak: "break-word", // Break long words
					}}
				>
					{params.value}
				</Box>
			),
		},
		{
			field: "lastUpdatedTimeStamp",
			headerName: "Last updated on",
			sortable: false,
			flex: 1,
			align: "center",
			renderCell: (params) => (
				<Box
					sx={{
						textAlign: "center",
						whiteSpace: "normal", // Allow text to wrap
						wordBreak: "break-word", // Break long words
					}}
				>
					{format(new Date(params.value), "dd MMM yyyy HH:mm")}
				</Box>
			),
		},
		{
			field: "lastUpdatedBy",
			headerName: "Last updated by",
			sortable: false,
			flex: 1,
			align: "center",
			renderCell: (params) => (
				<Box
					sx={{
						textAlign: "center",
						whiteSpace: "normal", // Allow text to wrap
						wordBreak: "break-word", // Break long words
					}}
				>
					{params.value}
				</Box>
			),
		},
		{
			field: "status",
			headerName: "Status",
			sortable: false,
			width: 130,
			align: "center",
		},
		{
			field: "action",
			headerName: "Actions",
			align: "center",
			width: 150,
			renderCell: (params) => (
				<Grid
					container
					style={{
						alignItems: "center",
						boxSizing: "border-box",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "center",
						gap: "0.75rem",
					}}
				>
					<Grid item>
						<IconButton size="small" aria-label="view" onClick={() => onView(params.row.id)} disabled={params.row.status !== completeStatusValue}>
							<Visibility style={{ color: params.row.status !== completeStatusValue ? "#808080" : "#2F5597" }} />
						</IconButton>
					</Grid>
					<Grid>
						<IconButton size="small" aria-label="edit" onClick={() => onEdit(params.row.id)} disabled={params.row.status === inProgressStatusValue}>
							<Edit style={{ color: params.row.status === inProgressStatusValue ? "#808080" : "#2F5597" }} />
						</IconButton>
					</Grid>
					<Grid item>
						<IconButton size="small" aria-label="delete" onClick={() => onDelete(params.row.id)} disabled={params.row.status === inProgressStatusValue}>
							<Delete style={{ color: params.row.status === inProgressStatusValue ? "#808080" : "#2F5597" }} />
						</IconButton>
					</Grid>
				</Grid>
			),
			sortable: false,
		},
	];

	useEffect(() => {
		if (data && data.length > 0) {
			const scenarioListData: IScenarioDetails[] = [];
			data
				.sort((firstRecord: IScenarioDetails, secondRecord: IScenarioDetails) => {
					if (firstRecord.lastUpdatedTimeStamp > secondRecord.lastUpdatedTimeStamp) {
						return -1;
					} else {
						return 1;
					}
				})
				.forEach((scenarioDetail: IScenarioDetails) => {
					scenarioListData.push({
						id: scenarioDetail.scenarioName,
						scenarioName: scenarioDetail.scenarioName,
						createdBy: scenarioDetail.createdBy,
						createdTimeStamp: scenarioDetail.createdTimeStamp,
						lastUpdatedBy: scenarioDetail.lastUpdatedBy,
						lastUpdatedTimeStamp: scenarioDetail.lastUpdatedTimeStamp,
						status: scenarioDetail.status,
					});
				});
			const lastValue = scenarioListData.length < pageSize * pageNo ? scenarioListData.length : pageSize * pageNo;
			setScenarioTableData(scenarioListData.slice((pageNo - 1) * pageSize, lastValue));
			setRecordCount(scenarioListData.length);
		} else {
			setScenarioTableData([]);
		}
	}, [data, pageNo, pageSize]);

	return (
		<>
			<Grid style={{ height: "60vh", width: "100%" }} position={"relative"}>
				<Indicator position="absolute" show={loader} />
				<DataGrid
					getRowId={(row) => row.id}
					className={classes.scenarioTable}
					rows={scenarioTableData}
					rowHeight={gridRowHeight}
					getRowClassName={() => classes.tableRow}
					columns={columns}
					pagination={true}
					rowCount={recordCount}
					disableColumnMenu={true}
					disableSelectionOnClick={true}
					paginationMode="server"
					components={{ NoRowsOverlay: noRowsOverlay }}
					pageSize={pageSize}
					onPageChange={(no) => {
						setPageNo(no + 1);
					}}
					onPageSizeChange={(size: number) => {
						setPageSize(size);
					}}
					rowsPerPageOptions={[5, 10, 20, 50]}
					page={pageNo - 1}
					componentsProps={{
						row: {
							sx: {
								padding: "5px", // Add padding inside each row
							},
						},
						footer: { sx: { borderTop: "2px solid #DFDFDF" } },
					}}
				/>
			</Grid>
			{showDeletePopup && <ConfirmationPopUp isDelete={true} callback={onDeleteConfirmation} suffix={scenarioToBeDeleted} />}
		</>
	);
};
export default ScenarioList;
