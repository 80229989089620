import { IMixSelectedFilters } from "../types/mixAndAssortment";

export const mixOverallFilters = {
	country: {
		title: "Country",
		options: [],
		placeholder: "Country",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		first: true,
		children: "dateTimePeriod",
	},
	dateTimePeriod: {
		title: "Date Time Period",
		options: [],
		placeholder: "Time Period",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		sort: { enable: false },
		children: "businessUnit",
	},
	businessUnit: {
		title: "Business Unit",
		options: [],
		placeholder: "Business Unit",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		children: "endTimePeriod",
	},
	endTimePeriod: {
		title: "End Time Period",
		options: [],
		placeholder: "End Time Period",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		sort: { enable: false },
		last: true,
	},
};

export const mixOverallFilterOrder = [
	{ key: "country", order: 1 },
	{ key: "dateTimePeriod", order: 2 },
	{ key: "businessUnit", order: 3 },
	{ key: "endTimePeriod", order: 4 },
];

export const mixGeoFilters = {
	level: {
		title: "Level",
		options: [],
		placeholder: "Level",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		first: true,
		children: "channel",
	},
	channel: {
		title: "Channel",
		options: [],
		placeholder: "Channel",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		children: "region",
	},
	region: {
		title: "Region",
		options: [],
		placeholder: "Region",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		children: "storeSegment",
	},
	storeSegment: {
		title: "Store Segment",
		options: [],
		placeholder: "Store Segment",
		all: true,
		multiple: true,
		defaultSelectAll: false,
		last: true,
	},
};

export const mixGeoFilterOrder = [
	{ key: "level", order: 1 },
	{ key: "channel", order: 2 },
	{ key: "region", order: 3 },
	{ key: "storeSegment", order: 4 },
];

export const mixGoalOptions = [
	{ label: "Maximum Revenue per Kg", value: "revenue" },
	{ label: "Maximum Profit Margin", value: "profit" },
	{ label: "Maximum Market Share", value: "marketShare" },
];

export const mixConstraintFilters = {
	region: {
		title: "Region",
		options: [],
		placeholder: "Region",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		first: true,
		children: "storeSegment",
	},
	storeSegment: {
		title: "Store Segment",
		options: [],
		placeholder: "Store Segment",
		all: false,
		multiple: false,
		defaultSelectAll: false,
		last: true,
	},
};

export const mixConstraintFilterOrder = [
	{ key: "region", order: 1 },
	{ key: "storeSegment", order: 2 },
];

export const mixConstraintUpdateAllObject = [
	{ key: "minMix", title: "Min Mix" },
	{ key: "maxMix", title: "Max Mix" },
];

export const mixFieldHeaderNameMapping = {
	channel: "Channel",
	region: "Region",
	storeSegment: "Store Segment",
	segment: "Segment",
	brand: "Brand",
	subBrand: "Sub Brand",
	packSize: "Pack Size",
	flavor: "Flavor",
	sku: "SKU",
	minMix: "Min Mix(%)",
	maxMix: "Max Mix(%)",
	currentMix: "Current Mix",
	idealMix: "Ideal Mix",
	mixDelta: "Mix Delta",
	currentMarketShare: "Current Market Share",
	idealMarketShare: "Ideal Market Share",
	netRevenuePerKg: "Net Revenue Per KG",
	nopbtPerKg: "NOPBT Per KG",
	revenueSop: "Revenue SOP",
	nopbtSop: "NOPBT SOP",
	shareSop: "Share SOP",
};

export const mixSelectedFiltersObject: IMixSelectedFilters = {
	country: "",
	dateTimePeriod: "",
	businessUnit: "",
	endTimePeriod: "",
	level: "",
	channel: "",
	region: [],
	storeSegment: [],
	goal: "",
	assortmentScenario: "",
};

export const mixSummaryObject: { prefix: string; name: string; format?: "percent" }[] = [
	{ prefix: "base", name: "Base Scenario" },
	{ prefix: "user", name: "User Scenario" },
	{ prefix: "absIncremental", name: "Abs. Incremental" },
	{ prefix: "percentChange", name: "Percent Change (%)", format: "percent" },
];

export const mixSummaryCardObject: { label: string; suffix: string; format?: "percent" }[] = [
	{ label: "Net Revenue", suffix: "NetRevenue" },
	{ label: "NOPBT", suffix: "NOPBT" },
	{ label: "NOPBT %", suffix: "NOPBTPercent", format: "percent" },
	{ label: "Market Share", suffix: "MarketShare", format: "percent" },
];

export const mixPortfolioLevelOptions = [
	{ label: "Segment", value: "segment", columnsToHide: ["brand", "subBrand", "packSize", "sku", "channel", "region", "storeSegment", "regionStoreSegment"] },
	{ label: "Brand", value: "brand", columnsToHide: ["subBrand", "packSize", "sku", "channel", "region", "storeSegment", "regionStoreSegment"] },
	{ label: "SKU", value: "sku", columnsToHide: ["channel", "region", "storeSegment", "regionStoreSegment"] },
];

export const mixGeoLevelOptionsConfig = {
	"Within Channel": [
		{
			label: "Region",
			value: "region",
			columnsToHide: ["segment", "brand", "subBrand", "packSize", "sku", "storeSegment", "regionStoreSegment"],
			applicableGoal: ["revenue", "profit", "marketShare"],
		},
		{
			label: "Store Segment",
			value: "storeSegment",
			columnsToHide: ["segment", "brand", "subBrand", "packSize", "sku", "region", "regionStoreSegment"],
			applicableGoal: ["revenue", "profit"],
		},
		{
			label: "Region & Store Segment",
			value: "regionStoreSegment",
			columnsToHide: ["segment", "brand", "subBrand", "packSize", "sku", "regionStoreSegment"],
			applicableGoal: ["revenue", "profit"],
		},
	],
	"Market Share": [
		{
			label: "Region",
			value: "region",
			columnsToHide: ["segment", "brand", "subBrand", "packSize", "sku", "storeSegment", "regionStoreSegment"],
			applicableGoal: ["revenue", "profit", "marketShare"],
		},
	],
};

export const mixBubbleChartDataOptions = [
	{ label: "Portfolio", value: "portfolio" },
	{ label: "Geo Level", value: "geo" },
];

export const mixBubbleChartYAxisConfig = {
	revenue: { dataPoint: "revenueSop", label: "Revenue SOP" },
	profit: { dataPoint: "nopbtSop", label: "NOPBT SOP" },
	marketShare: { dataPoint: "shareSop", label: "Share SOP" },
};
