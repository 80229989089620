import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Box, Card, CardContent, Grid, IconButton, Stack } from "@mui/material";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import ScenarioList from "./ScenarioList";
import SearchBar from "../../components/SearchBar/SearchBar";
import { PrimaryBtn } from "../../styles/Common.Styled";
import { toast } from "../../store/actions/toast.action";
import { IScenarioDetails } from "../../types/mixAndAssortment";
import { deleteScenarios, getScenarios } from "../../util/mixAndAssortmentServices";

const ScenarioLibrary: FC<{ type: string; newScenario: () => void; viewScenario: (scenario: string) => void; editScenario: (scenario: string) => void }> = ({
	type,
	newScenario,
	viewScenario,
	editScenario,
}) => {
	const dispatch = useDispatch();
	const [searchScenarioValue, setSearchScenarioValue] = useState("");
	const [allScenarioData, setAllScenarioData] = useState<IScenarioDetails[]>([]);
	const [scenarioData, setScenarioData] = useState<IScenarioDetails[]>([]);
	const [loader, setLoader] = useState(false);
	const fetchScenarioData = () => {
		setLoader(true);
		getScenarios(type).then((response) => {
			if (response && response.data) {
				setAllScenarioData(response.data);
			} else if (response && response.error) {
				dispatch(toast("Get Model Scenario Library: " + response.error, true, 2000, "error"));
			}

			setLoader(false);
		});
	};
	const deleteScenario = (scenarioName: string) => {
		setLoader(true);
		deleteScenarios(scenarioName, type).then((response) => {
			if (response && response.data) {
				fetchScenarioData();
			} else if (response && response.error) {
				dispatch(toast("Delete Scenario: " + response.error, true, 2000, "error"));
			}
			setLoader(false);
		});
	};
	useEffect(() => {
		fetchScenarioData();
	}, []);

	useEffect(() => {
		if (allScenarioData.length > 0) {
			setScenarioData(
				allScenarioData
					.filter((scenario) => scenario.scenarioName.toLowerCase().includes(searchScenarioValue.toLowerCase()))
					.sort((a: IScenarioDetails, b: IScenarioDetails) => {
						if (a.lastUpdatedTimeStamp > b.lastUpdatedTimeStamp) {
							return 1;
						} else {
							return -1;
						}
					})
			);
		}
	}, [allScenarioData, searchScenarioValue]);

	return (
		<Card>
			<CardContent style={{ padding: "20px 30px" }}>
				<Box className="m-b-30">
					<Stack direction={"row"} alignItems={"center"}>
						<Box>
							<SearchBar searchQuery={searchScenarioValue} callBack={setSearchScenarioValue} width={400} placeHolder="Search for a scenario by name" />
						</Box>
						<Box style={{ marginLeft: "auto" }}>
							<Stack direction={"row"}>
								<Grid style={{ marginRight: "5px" }}>
									<IconButton onClick={fetchScenarioData}>
										<RefreshOutlinedIcon />
									</IconButton>
								</Grid>
								<Grid>
									<PrimaryBtn color="primary" onClick={newScenario}>
										Add New Scenario
									</PrimaryBtn>
								</Grid>
							</Stack>
						</Box>
					</Stack>
				</Box>
				<ScenarioList data={scenarioData} onView={viewScenario} onEdit={editScenario} deleteScenario={deleteScenario} loader={loader} />
			</CardContent>
		</Card>
	);
};

export default ScenarioLibrary;
