import { IKeyValueConfiguration } from "../types/common";

export function sortEndTimePeriod(monthSortOrder: IKeyValueConfiguration[], endTimeOptions: string[]): string[] {
	const updatedEndTimeOptions = endTimeOptions.sort((startTime: string, endTime: string) => {
		const startTimeValues = startTime.split("-");
		const endTimeValues = endTime.split("-");
		const startTimeOrderKey = monthSortOrder.find((value) => value.label === startTimeValues[0])?.value ?? -1;
		const endTimeOrderKey = monthSortOrder.find((value) => value.label === endTimeValues[0])?.value ?? -1;
		if (startTimeValues[1] === endTimeValues[1] && startTimeOrderKey < endTimeOrderKey) {
			return 1;
		} else if (startTimeValues[1] === endTimeValues[1] && startTimeOrderKey >= endTimeOrderKey) {
			return -1;
		} else if (startTimeValues[1] < endTimeValues[1]) {
			return 1;
		} else {
			return -1;
		}
	});
	return updatedEndTimeOptions;
}

export function getTimeRangeValues(monthSortOrder: IKeyValueConfiguration[], selectedEndTimePeriod: string, selectedDateTimePeriod: string) {
	let monthValues: number = 0;
	switch (selectedDateTimePeriod) {
		case "3MM":
			monthValues = 3;
			break;
		case "6MM":
			monthValues = 6;
			break;
		case "12MM":
			monthValues = 12;
			break;
	}
	const selectedEndTimePeriodValues: string[] = selectedEndTimePeriod.split("-");
	const monthNumber: number = parseInt(monthSortOrder.find((value) => value.label === selectedEndTimePeriodValues[0])?.value.toString() ?? "0");
	let monthDifference: number = monthNumber - monthValues + 1;
	let yearNumber: number = parseFloat(selectedEndTimePeriodValues[1]);
	if (monthDifference <= 0) {
		monthDifference = monthDifference + 12;
		yearNumber--;
	}
	const startMonth: string = monthSortOrder.find((value) => value.value === monthDifference)?.label ?? "";
	return startMonth + "-" + yearNumber + " - " + selectedEndTimePeriod;
}
