import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, Skeleton, Stack } from "@mui/material";
import { IMnAFilterConfigurations, IPortfolioGeoFilter, IExecutionPortfolioTableData } from "../../../../types/mixAndAssortment";
import { columnsToDownloadPortfolioTable, portfolioColumns } from "../ExecutionTable/TableConfig";
import dataObject from "../../../../mocks/executionTracker";
import CommonMnAFilters from "../../../../components/MnAFilters/CommonMnAFilters/CommonMAFilters";
import Dropdown from "../../../../components/Dropdown";
import Indicator from "../../../../components/Loader";
import DataGridWithFilters from "../../../../components/DataGridWithFilters";
import { IconDownload } from "../../../../assets/icons/mna";
import { OrangeBtn, PrimaryBtn } from "../../../../styles/Common.Styled";
import { Title } from "../../../../components/Dashboard/Dashboard.Styled";
import { DropdownTitle } from "../../../../components/DashboardFilters/DashboardFilters.Styled";
import { convertToCsvWithKeys, downloadInCsvFormat } from "../../../../util/mixAndAssortmentServices";

const disableApplyBtn = (selectedFilters: IMnAFilterConfigurations) => {
	let flag: boolean = true;
	if (
		selectedFilters.assortmentScenario?.length &&
		selectedFilters.businessUnit.length &&
		selectedFilters.channel.length &&
		selectedFilters.country.length &&
		selectedFilters.dateTimePeriod.length &&
		selectedFilters.mixScenario?.length &&
		selectedFilters.region.length &&
		selectedFilters.storeSegment.length &&
		selectedFilters.portfolioGeo?.level &&
		selectedFilters.portfolioGeo?.channel.length &&
		selectedFilters.portfolioGeo?.region.length &&
		selectedFilters.portfolioGeo?.storeSegment.length
	)
		flag = false;
	return flag;
};

const filterTableColumns = (level: string) => {
	const exclusionMap: Record<string, number[]> = {
		Segment: [1, 2, 3, 4],
		Brand: [2, 3, 4],
	};
	const exclusions = exclusionMap[level] || [];
	return portfolioColumns.filter((_, index) => !exclusions.includes(index));
};

const Portfolio: React.FC<{
	tableData: IExecutionPortfolioTableData[];
	selectedFilters: IMnAFilterConfigurations;
	setSelectedFilters: Dispatch<SetStateAction<IMnAFilterConfigurations>>;
	onApplyFilter: () => void;
	skeleton: boolean;
	loader: Boolean;
}> = ({ tableData, selectedFilters, setSelectedFilters, onApplyFilter, skeleton, loader }) => {
	const [geoFilterOption, setGeoFilterOption] = useState<IPortfolioGeoFilter>(dataObject.overallFilterOptions.portfolioGeo!);
	const [geoFilterData, setGeoFilterData] = useState(dataObject.portfolioFilter);
	const [levelFilteredColumns, setLevelFilteredColumns] = useState(portfolioColumns);

	const onChangeGeoFilter = (key, value) => {
		let selectedPortfolioValues: IPortfolioGeoFilter = {
			...(selectedFilters.portfolioGeo ?? {
				level: "",
				channel: [],
				region: [],
				storeSegment: [],
			}),
		};
		switch (key) {
			case "level": {
				selectedPortfolioValues = {
					...selectedPortfolioValues,
					level: value,
				};
				break;
			}
			case "channel": {
				selectedPortfolioValues = {
					...selectedPortfolioValues,
					channel: value,
					region: [],
					storeSegment: [],
				};
				break;
			}
			case "region": {
				selectedPortfolioValues = {
					...selectedPortfolioValues,
					region: value,
					storeSegment: [],
				};
				break;
			}
			case "storeSegment": {
				selectedPortfolioValues = {
					...selectedPortfolioValues,
					storeSegment: value,
				};
				break;
			}
		}
		const selectedValues: IMnAFilterConfigurations = { ...selectedFilters, portfolioGeo: selectedPortfolioValues };
		setSelectedFilters(selectedValues);
	};

	const clearFilter = () => {
		const defaultFilters = {
			level: "Brand",
			channel: selectedFilters.channel,
			region: selectedFilters.region,
			storeSegment: selectedFilters.storeSegment,
		};
		const selectedValues = { ...selectedFilters, portfolioGeo: defaultFilters };
		setSelectedFilters(selectedValues);
	};

	const downloadPortfolioListData = () => {
		const keysArray = columnsToDownloadPortfolioTable.map((column) => column.field);
		const csvData = convertToCsvWithKeys(tableData, keysArray, columnsToDownloadPortfolioTable);
		downloadInCsvFormat(csvData, "Execution_Portfolio_Table_Data");
	};

	useEffect(() => {
		if (
			selectedFilters.channel.length &&
			selectedFilters.region.length &&
			selectedFilters.storeSegment.length &&
			selectedFilters.portfolioGeo?.channel.length === 0
		) {
			setGeoFilterOption({
				...geoFilterOption,
				level: selectedFilters.portfolioGeo?.level ?? "Brand",
				channel: selectedFilters.channel,
				region: selectedFilters.region,
				storeSegment: selectedFilters.storeSegment,
			});
		} else if (
			selectedFilters.channel.length === 0 &&
			selectedFilters.region.length === 0 &&
			selectedFilters.storeSegment.length === 0 &&
			selectedFilters.portfolioGeo?.channel.length === 0
		) {
			setGeoFilterOption({
				...geoFilterOption,
				level: "Brand",
				channel: [],
				region: [],
				storeSegment: [],
			});
		}
	}, [selectedFilters.channel, selectedFilters.region, selectedFilters.storeSegment, tableData]);

	useEffect(() => {
		setGeoFilterData({
			...geoFilterData,
			channel: { ...geoFilterData.channel, disabled: geoFilterOption.channel.length === 0 },
			region: { ...geoFilterData.region, disabled: geoFilterOption.region.length === 0 },
			storeSegment: { ...geoFilterData.storeSegment, disabled: geoFilterOption.storeSegment.length === 0 },
		});
	}, [selectedFilters]);

	useEffect(() => {
		setLevelFilteredColumns(filterTableColumns(selectedFilters.portfolioGeo?.level!));
	}, [tableData]);

	return (
		<>
			<Card className="m-b-20">
				<CardContent>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Title className="ellipsis">Portfolio</Title>
						<Stack direction="row" alignItems="center" spacing={4}>
							<Stack direction="row" alignItems="center" spacing={1}>
								<DropdownTitle>Level:</DropdownTitle>
								<Box width={130}>
									<Dropdown
										keyText="table-sub-heading"
										data={dataObject.portfolioLevelFilterData}
										multiple={false}
										onChange={(data) => onChangeGeoFilter("level", data[0])}
										placeholder="Level"
										defaultOption={"Brand"}
									/>
								</Box>
							</Stack>
							<Grid container>
								<Grid item style={{ marginLeft: "auto" }}>
									<PrimaryBtn onClick={downloadPortfolioListData} disabled={disableApplyBtn(selectedFilters) && tableData.length === 0}>
										<Grid display={"flex"} flexDirection={"row"} alignItems={"center"}>
											<IconDownload />
											<Grid marginLeft={"5px"}>Download CSV</Grid>
										</Grid>
									</PrimaryBtn>
								</Grid>
							</Grid>
						</Stack>
					</Stack>
					<Stack direction="row" className="m-t-20 m-l-20">
						<Box width="700px">
							<CommonMnAFilters
								filterData={geoFilterOption}
								onChange={onChangeGeoFilter}
								data={geoFilterData}
								defaultFilters={selectedFilters.portfolioGeo}
								filterOrder={dataObject.portfolioFilterOrder}
							/>
						</Box>
						<Grid className="p-l-16" marginBottom={5} mt={3}>
							<OrangeBtn color="secondary" className="m-r-20" onClick={clearFilter}>
								Reset Filter
							</OrangeBtn>
							<PrimaryBtn color="primary" onClick={onApplyFilter} disabled={disableApplyBtn(selectedFilters)}>
								Apply Filter
							</PrimaryBtn>
						</Grid>
					</Stack>
					{!skeleton ? (
						<Box height={"60vh"} overflow={"auto"} p={2}>
							{loader ? (
								<Indicator position={"relative"} show={loader} />
							) : (
								<DataGridWithFilters columns={levelFilteredColumns} rows={tableData} noRowsMessage="No data available" />
							)}
						</Box>
					) : (
						<Grid item xs={12}>
							<Skeleton variant="rectangular" height={250} />
						</Grid>
					)}
				</CardContent>
			</Card>
		</>
	);
};

export default Portfolio;
