import { GridColDef } from "@mui/x-data-grid";
import { formatNumber } from "../../../../util/helper";
import { headerCellWithFilter } from "../../../../components/DataGridWithFilters";

const portfolioColumns: GridColDef[] = [
	{
		field: "segment",
		headerName: "Segment",
		minWidth: 200,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
		filterable: true,
		renderHeader: headerCellWithFilter,
	},
	{
		field: "brand",
		headerName: "Brand",
		minWidth: 200,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
		filterable: true,
		renderHeader: headerCellWithFilter,
	},
	{
		field: "subBrand",
		headerName: "Sub Brand",
		minWidth: 200,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
		filterable: true,
		renderHeader: headerCellWithFilter,
	},
	{
		field: "packSize",
		headerName: "Pack Size",
		minWidth: 200,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
		filterable: true,
		renderHeader: headerCellWithFilter,
	},
	{
		field: "sku",
		headerName: "SKU",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
		filterable: true,
		renderHeader: headerCellWithFilter,
	},
	{
		field: "assortmentOverall",
		headerName: "Assortment score (%)",
		minWidth: 210,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "mustHaveDistributionScore",
		headerName: "Must have distribution score(%)",
		minWidth: 290,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "goodToHaveDistributionScore",
		headerName: "Good to have distribution score(%)",
		minWidth: 310,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "volumeMixResult",
		headerName: "Volume score (%)",
		minWidth: 190,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "revenueMixResult",
		headerName: "Revenue score (%)",
		minWidth: 190,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "profitMixResult",
		headerName: "NOPBT score(%)",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "mixScore",
		headerName: "Mix score(%)",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "revenuMix",
		headerName: "Revenue Mix(%)",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "parseFloat",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "netRevenueSOP",
		headerName: "SOP Revenue",
		minWidth: 190,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
		filterable: false,
	},
	{
		field: "nonPBTSOP",
		headerName: "SOP NOPBT",
		minWidth: 190,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
		filterable: false,
	},
	{
		field: "avgStorePenetration",
		headerName: "Penetration(Distribution)(%)",
		minWidth: 250,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "executionScore",
		headerName: "Execution score(%)",
		minWidth: 190,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "priceScore",
		headerName: "Price score(%)",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "netPriceRealisationScore",
		headerName: "Net price realisation score(%)",
		minWidth: 280,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "priceChangeVsRestOfTheMarketScore",
		headerName: "Price change Vs Rest of the market score(%)",
		minWidth: 350,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "priceChangeVsInflationScore",
		headerName: "Price change Vs Inflation score(%)",
		minWidth: 310,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "shelfAndMerchandizingScore",
		headerName: "Shelf & Merchandise score(%)",
		minWidth: 280,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "shelfSpaceScore",
		headerName: "Shelf space score(%)",
		minWidth: 220,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "exhibitionScore",
		headerName: "Exhibition score(%)",
		minWidth: 220,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
];

const geoLevelSummaryColumns: GridColDef[] = [
	{
		field: "channel",
		headerName: "Channel",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
		filterable: true,
		renderHeader: headerCellWithFilter,
	},
	{
		field: "region",
		headerName: "Region",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
		filterable: true,
		renderHeader: headerCellWithFilter,
	},
	{
		field: "storeSegment",
		headerName: "Store Segment",
		minWidth: 200,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "string",
		filterable: true,
		renderHeader: headerCellWithFilter,
	},
	{
		field: "assortmentOverAll",
		headerName: "Assortment score(%)",
		minWidth: 200,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "parseFloat",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "mustHaveDistributionScore",
		headerName: "Must have distribution score(%)",
		minWidth: 300,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "goodToHaveDistributionScore",
		headerName: "Good to have distribution score(%)",
		minWidth: 300,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "volumeMixResult",
		headerName: "Volume score (%)",
		minWidth: 200,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "revenueMixResult",
		headerName: "Revenue score (%)",
		minWidth: 190,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "profitMixResult",
		headerName: "NOPBT score (%)",
		minWidth: 180,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "mixScore",
		headerName: "Mix score(%)",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "revenuMix",
		headerName: "Revenue Mix(%)",
		minWidth: 180,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "netRevenueSOP",
		headerName: "SOP Revenue",
		minWidth: 150,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
		filterable: false,
	},
	{
		field: "nonPBTSOP",
		headerName: "SOP NOPBT",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}` : value),
		filterable: false,
	},
	{
		field: "avgStorePenetration",
		headerName: "Penetration(Distribution)(%)",
		minWidth: 270,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "executionScore",
		headerName: "Execution score(%)",
		minWidth: 190,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "priceScore",
		headerName: "Price score(%)",
		minWidth: 170,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "netPriceRealisationScore",
		headerName: "Net price realisation score(%)",
		minWidth: 270,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "priceChangeVsRestOfTheMarketScore",
		headerName: "Price change Vs Rest of the market score(%)",
		minWidth: 370,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "priceChangeVsInflationScore",
		headerName: "Price change Vs Inflation score(%)",
		minWidth: 320,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "shelfAndMerchandizingScore",
		headerName: "Shelf & Merchandise score(%)",
		minWidth: 270,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "shelfSpaceScore",
		headerName: "Shelf space score(%)",
		minWidth: 200,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
	{
		field: "exhibitionScore",
		headerName: "Exhibition score(%)",
		minWidth: 190,
		align: "center",
		flex: 1,
		headerAlign: "center",
		type: "number",
		valueFormatter: ({ value }) => (!isNaN(value) ? `${formatNumber(value, 1)}%` : value),
		filterable: false,
	},
];

const columnsToDownloadPortfolioTable = [
	{ headerName: "Segment", field: "segment" },
	{ headerName: "Brand", field: "brand" },
	{ headerName: "Sub Brand", field: "subBrand" },
	{ headerName: "Pack Size", field: "packSize" },
	{ headerName: "SKU", field: "sku" },
	{ headerName: "Assortment score (%)", field: "assortmentOverall" },
	{ headerName: "Must have distribution score(%)", field: "mustHaveDistributionScore" },
	{ headerName: "Good to have distribution score(%)", field: "goodToHaveDistributionScore" },
	{ headerName: "Volume score (%)", field: "volumeMixResult" },
	{ headerName: "Revenue score (%)", field: "revenueMixResult" },
	{ headerName: "NOPBT score(%)", field: "profitMixResult" },
	{ headerName: "Mix score(%)", field: "mixScore" },
	{ headerName: "Revenue Mix(%)", field: "revenuMix" },
	{ headerName: "SOP Revenue", field: "netRevenueSOP" },
	{ headerName: "SOP NOPBT", field: "nonPBTSOP" },
	{ headerName: "Penetration(Distribution)(%)", field: "avgStorePenetration" },
	{ headerName: "Execution score(%)", field: "executionScore" },
	{ headerName: "Price score(%)", field: "priceScore" },
	{ headerName: "Net price realisation score(%)", field: "netPriceRealisationScore" },
	{ headerName: "Price change Vs Rest of the market score(%)", field: "priceChangeVsRestOfTheMarketScore" },
	{ headerName: "Price change Vs Inflation score(%)", field: "priceChangeVsInflationScore" },
	{ headerName: "Shelf & Merchandise score(%)", field: "shelfAndMerchandizingScore" },
	{ headerName: "Shelf space score(%)", field: "shelfSpaceScore" },
	{ headerName: "Exhibition score(%)", field: "exhibitionScore" },
];

const columnsToDownloadGeoTable = [
	{ headerName: "Channel", field: "channel" },
	{ headerName: "Region", field: "region" },
	{ headerName: "Store Segment", field: "storeSegment" },
	{ headerName: "Assortment score(%)", field: "assortmentOverAll" },
	{ headerName: "Must have distribution score(%)", field: "mustHaveDistributionScore" },
	{ headerName: "Good to have distribution score(%)", field: "goodToHaveDistributionScore" },
	{ headerName: "Volume score (%)", field: "volumeMixResult" },
	{ headerName: "Revenue score (%)", field: "revenueMixResult" },
	{ headerName: "NOPBT score (%)", field: "profitMixResult" },
	{ headerName: "Mix score(%)", field: "mixScore" },
	{ headerName: "Revenue Mix(%)", field: "revenuMix" },
	{ headerName: "SOP Revenue", field: "netRevenueSOP" },
	{ headerName: "SOP NOPBT", field: "nonPBTSOP" },
	{ headerName: "Penetration(Distribution)(%)", field: "avgStorePenetration" },
	{ headerName: "Execution score(%)", field: "executionScore" },
	{ headerName: "Price score(%)", field: "priceScore" },
	{ headerName: "Net price realisation score(%)", field: "netPriceRealisationScore" },
	{ headerName: "Price change Vs Rest of the market score(%)", field: "priceChangeVsRestOfTheMarketScore" },
	{ headerName: "Price change Vs Inflation score(%)", field: "priceChangeVsInflationScore" },
	{ headerName: "Shelf & Merchandise score(%)", field: "shelfAndMerchandizingScore" },
	{ headerName: "Shelf space score(%)", field: "shelfSpaceScore" },
	{ headerName: "Exhibition score(%)", field: "exhibitionScore" },
];

export { portfolioColumns, geoLevelSummaryColumns, columnsToDownloadGeoTable, columnsToDownloadPortfolioTable };
