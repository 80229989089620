import { Avatar, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import RadioButtonGroup from "../../../../components/UI-components/RadioButtonGroup/RadioButtonGroup";
import dataObject from "../../../../mocks/executionTracker";

const formatNumber = (value, decimalPoints) => {
	let absValue = Math.abs(value);
	const suffixes = ["", "K", "M", "B", "T"];
	let suffixIndex = 0;

	while (absValue >= 1000 && suffixIndex < suffixes.length - 1) {
		absValue /= 1000;
		suffixIndex++;
	}
	const formattedValue = value >= 0 ? absValue : -absValue;
	const suffix = suffixes[suffixIndex];

	return `${formattedValue.toFixed(decimalPoints)} ${suffix}`;
};
const SizeOfPrize: FC<{ data; chartSkeleton?: boolean }> = ({ data, chartSkeleton = true }) => {
	const [selectedOpportunity, setSelectedOpportunity] = useState(dataObject.sizeOfPrizeRadio.defaultOption.value);
	const [opportunityData, setOpportunityData] = useState<any>();

	useEffect(() => {
		if (data && data[selectedOpportunity]) {
			setOpportunityData(data[selectedOpportunity]);
		}
	}, [data, selectedOpportunity]);
	return (
		<>
			{!chartSkeleton ? (
				<>
					<Grid container>
						<Grid item display="flex" alignItems="center">
							<Typography color="#000000" marginRight={1}>
								SOP:
							</Typography>
							<RadioButtonGroup
								direction="row"
								color="#000"
								showButton={true}
								showSkeleton={false}
								data={dataObject.sizeOfPrizeRadio.options}
								defaultOption={selectedOpportunity}
								select={(data) => setSelectedOpportunity(data)}
							/>
						</Grid>
					</Grid>
					<List>
						{opportunityData &&
							opportunityData.map((item) => {
								return (
									<>
										<ListItem alignItems="flex-start">
											<ListItemText
												primary={item.name.toUpperCase()}
												primaryTypographyProps={{
													sx: { color: "#7A7A7A" },
												}}
												secondary={
													<React.Fragment>
														<Stack>
															<Stack direction="row" alignItems="center" justifyContent="space-between">
																<Typography component="span" variant="body2" sx={{ color: "text.primary", display: "inline" }}>
																	SOP Revenue :
																</Typography>
																<Typography color="#335899">{formatNumber(item.sop, 1)}</Typography>
															</Stack>
															<Stack direction="row" alignItems="center" justifyContent="space-between">
																<Typography component="span" variant="body2" sx={{ color: "text.primary", display: "inline" }}>
																	SOP NOPBT
																</Typography>
																<Typography color="#335899">{formatNumber(item.nopbt, 1)}</Typography>
															</Stack>
														</Stack>
													</React.Fragment>
												}
											/>
										</ListItem>
										<Divider variant="inset" component="li" />
									</>
								);
							})}
					</List>
				</>
			) : (
				<Grid item xs={12}>
					<Skeleton variant="rectangular" height={440} />
				</Grid>
			)}
		</>
	);
};

export default SizeOfPrize;
