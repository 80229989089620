import { IExecutionTrackerPayload, IMnAFilterConfigurations } from "../../../types/mixAndAssortment";

/* Creates a GraphQL query to get the execution tracker simulation data for Execution Tracker page.
 * @param {IExecutionTrackerPayload} payload - The selected filter details.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_SIMULATIONDATA_QUERY = (payload: IExecutionTrackerPayload) => {
	return {
		query: `query { 
    getExecutionTrackerSimulationData( 
      country : "${payload.country}", 
      dateTimePeriod : "${payload.dateTimePeriod}", 
      businessUnit : "${payload.businessUnit}", 
      endTimePeriod : "${payload.endTimePeriod}", 
      channel: ${JSON.stringify(payload.channel)}, 
      region: ${JSON.stringify(payload.region)}, 
      storeSegment: ${JSON.stringify(payload.storeSegment)}, 
      assortmentScenarioName: "${payload.assortmentScenarioName}", 
      mixScenarioName: "${payload.mixScenarioName}", 
      goodToHaveSkus: ${payload.goodToHaveSkus}, 
      mustHaveSKUs: ${payload.mustHaveSKUs} 
    ) { 
        overallScore 
        assortment { 
          overallScore 
          status 
          mustHaveSkusDistribution: mustHaveSkUsDistribution
          goodToHaveSkusDistribution: goodToHaveSkUsDistribution
        } 
        mix { 
          overallScore 
          status 
          volumeMix 
          netRevenue 
          nopbt 
        } 
        price { 
          overallScore 
          status 
          netPriceRealisation 
          priceChangeVsRestOfMarket 
          priceChangeVsInflation 
        } 
        shelfAndMerchandising { 
          overallScore 
          status 
          shelfSpace 
          exhibition 
        } 
        currentAverage { 
          mustHaveCurrentAverage 
          goodToHaveCurrentAverage 
        } 
    } 
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker opportunity data for Execution Tracker page.
 * @param {IExecutionTrackerPayload} payload - The selected filter details.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_OPPORTUNITYDATA_QUERY = (payload: IExecutionTrackerPayload) => {
	return {
		query: `query { 
    getExecutionOpportunityData( 
      country : "${payload.country}", 
      dateTimePeriod : "${payload.dateTimePeriod}", 
      businessUnit : "${payload.businessUnit}", 
      endTimePeriod : "${payload.endTimePeriod}", 
      channel: ${JSON.stringify(payload.channel)}, 
      region: ${JSON.stringify(payload.region)}, 
      storeSegment: ${JSON.stringify(payload.storeSegment)}, 
      assortmentScenarioName: "${payload.assortmentScenarioName}", 
      mixScenarioName: "${payload.mixScenarioName}", 
      goodToHaveSkus: ${payload.goodToHaveSkus}, 
      mustHaveSKUs: ${payload.mustHaveSKUs} 
    ) { 
        assortmentOpportunities {
          name 
          sop 
          nopbt 
        } 
        mixOpportunities { 
          name 
          sop 
          nopbt 
        } 
    } 
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker overview KPI data for Execution Tracker page.
 * @param {IExecutionTrackerPayload} payload - The selected filter details.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_OVERVIEWKPI_QUERY = (payload: IExecutionTrackerPayload) => {
	return {
		query: `query { 
    getExecutionOverviewKpiData( 
      country : "${payload.country}", 
      dateTimePeriod : "${payload.dateTimePeriod}", 
      businessUnit : "${payload.businessUnit}", 
      endTimePeriod : "${payload.endTimePeriod}", 
      channel: ${JSON.stringify(payload.channel)}, 
      region: ${JSON.stringify(payload.region)}, 
      storeSegment: ${JSON.stringify(payload.storeSegment)}, 
      assortmentScenarioName: "${payload.assortmentScenarioName}", 
      mixScenarioName: "${payload.mixScenarioName}", 
      goodToHaveSkus: ${payload.goodToHaveSkus}, 
      mustHaveSKUs: ${payload.mustHaveSKUs} 
    ) { 
        netRevenue { 
          netRevenueValue 
          percentageChange 
        } 
        profit { 
          profitPercent 
          percentageChange 
        } 
        volume { 
          volume 
          percentageChange 
        } 
    } 
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker overview KPI data for Execution Tracker page.
 * @param {IExecutionTrackerPayload} payload - The selected filter details.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_PORTFOLIO_TABLEDATA_QUERY = (payload: IExecutionTrackerPayload) => {
	return {
		query: `query { 
    getExecutionPortfolioTableData( 
      country : "${payload.country}", 
      dateTimePeriod : "${payload.dateTimePeriod}", 
      businessUnit : "${payload.businessUnit}", 
      endTimePeriod : "${payload.endTimePeriod}", 
      channel: ${JSON.stringify(payload.channel)}, 
      region: ${JSON.stringify(payload.region)}, 
      storeSegment: ${JSON.stringify(payload.storeSegment)}, 
      assortmentScenarioName: "${payload.assortmentScenarioName}", 
      mixScenarioName: "${payload.mixScenarioName}", 
      goodToHaveSkus: ${payload.goodToHaveSkus}, 
      mustHaveSKUs: ${payload.mustHaveSKUs} 
      level: "${payload.portfolioGeoFilter?.level}", 
      portfolioGeoFilter: { 
        channel: ${JSON.stringify(payload.portfolioGeoFilter?.channel)}, 
        region: ${JSON.stringify(payload.portfolioGeoFilter?.region)}, 
        storeSegment: ${JSON.stringify(payload.portfolioGeoFilter?.storeSegment)} 
      } 
    )  { 
        segment 
        brand 
        subBrand 
        packSize 
        avgStorePenetration 
        sku 
        mixScore 
        volumeMixResult 
        revenueMixResult 
        profitMixResult 
        revenuMix 
        assortmentOverall 
        netRevenueSOP 
        nonPBTSOP 
        priceScore 
        shelfAndMerchandizingScore 
        executionScore 
        mustHaveDistributionScore 
        goodToHaveDistributionScore 
        priceChangeVsRestOfTheMarketScore 
        netPriceRealisationScore 
        priceChangeVsInflationScore 
        exhibitionScore 
        shelfSpaceScore 
    } 
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker overall filter data for Execution Tracker page.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_OVERALL_FILTER_QUERY = () => {
	return {
		query: `query {
    getExecutionInputFilterData(
      dashboardName: "Execution"
    ) {
        country
        dateTimePeriod
        businessUnit
        endTimePeriod
    }
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker geo filter data for Execution Tracker page.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_GEO_FILTER_QUERY = (payload: IMnAFilterConfigurations) => {
	return {
		query: `query {
    getExecutionGeoFilterData(
      dashboardName: "Execution"
      country: "${payload.country}"
      dateTimePeriod: "${payload.dateTimePeriod}"
      businessUnit: "${payload.businessUnit}"
      endTimePeriod: "${payload.endTimePeriod}"
    ) {
       channel
       region
       storeSegment
    }
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker current average data for Execution Tracker page.
 * @param {IMnAFilterConfigurations} payload - The selected filter details.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_USERINPUT_QUERY = (payload: IMnAFilterConfigurations) => {
	return {
		query: `query {
    getUserScenariosExecution(
      dashboardName : "Execution"
      country: "${payload.country}"
      dateTimePeriod: "${payload.dateTimePeriod}"
      businessUnit: "${payload.businessUnit}"
      endTimePeriod: "${payload.endTimePeriod}"
      channel: ${JSON.stringify(payload.channel)}
      region: ${JSON.stringify(payload.region)}
      storeSegment: ${JSON.stringify(payload.storeSegment)}
    ) {
        assortmentScenarios
        mixScenarios{
          scenarioName
          channel
          region
          storeSegment
        }
    }
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker current average data for Execution Tracker page.
 * @param {IMnAFilterConfigurations} payload - The selected filter details.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_CURRENT_AVG_QUERY = (payload: IMnAFilterConfigurations) => {
	return {
		query: `query { 
    getExecutionCurrentAverage( 
      country : "${payload.country}" 
      businessUnit : "${payload.businessUnit}"
      channel: ${JSON.stringify(payload.channel)}
      region: ${JSON.stringify(payload.region)}
      storeSegment: ${JSON.stringify(payload.storeSegment)} 
      assortmentScenarioName: "${payload.assortmentScenario}"
    )  { 
        goodToHaveSkuCurrentAverage 
        mustHaveSkuCurrentAverage 
        goodToHaveSKU 
        mustHaveSKU 
    } 
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker overview KPI data for Execution Tracker page.
 * @param {IExecutionTrackerPayload} payload - The selected filter details.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_GEO_LEVEL_TABLEDATA_QUERY = (payload: IExecutionTrackerPayload) => {
	return {
		query: `query { 
    getExecutionGeoLevelSummaryTableData( 
      country : "${payload.country}", 
      dateTimePeriod : "${payload.dateTimePeriod}"
      businessUnit : "${payload.businessUnit}"
      endTimePeriod : "${payload.endTimePeriod}" 
      channel: ${JSON.stringify(payload.channel)} 
      region: ${JSON.stringify(payload.region)}
      storeSegment: ${JSON.stringify(payload.storeSegment)} 
      assortmentScenarioName: "${payload.assortmentScenarioName}"
      mixScenarioName: "${payload.mixScenarioName}" 
      goodToHaveSkus: ${payload.goodToHaveSkus}
      mustHaveSKUs: ${payload.mustHaveSKUs} 
      level: "${payload.summaryGeoFilter?.level}"
      summaryGeoFilter: { 
        brand: ${JSON.stringify(payload.summaryGeoFilter?.brand)}, 
        segment: ${JSON.stringify(payload.summaryGeoFilter?.segment)}, 
        sku: ${JSON.stringify(payload.summaryGeoFilter?.sku)} 
      } 
    )  { 
        channel
    region
    storeSegment
    revenuMix
    assortmentOverAll
    netRevenueSOP
    nonPBTSOP
    priceScore
    shelfAndMerchandizingScore
    mixScore
    volumeMixResult
    revenueMixResult
    profitMixResult
    avgStorePenetration
    executionScore
    mustHaveDistributionScore
    goodToHaveDistributionScore
    priceChangeVsRestOfTheMarketScore
    netPriceRealisationScore
    priceChangeVsInflationScore
    exhibitionScore
    shelfSpaceScore 
    } 
} `,
	};
};

/* Creates a GraphQL query to get the execution tracker current average data for Execution Tracker page.
 * @param {IMnAFilterConfigurations} payload - The selected filter details.
 * @returns The GraphQL query.
 */
export const GET_EXECUTION_TRACKER_GEO_LEVEL_FILTER = (payload: IExecutionTrackerPayload) => {
	return {
		query: `query {
  getPortfolioGeoLevelFilter(
    country: "${payload.country}"
    dateTimePeriod: "${payload.dateTimePeriod}"
    businessUnit: "${payload.businessUnit}"
    endTimePeriod: "${payload.endTimePeriod}"
    channel: ${JSON.stringify(payload.channel)}
    region: ${JSON.stringify(payload.region)}
    storeSegment: ${JSON.stringify(payload.storeSegment)}
  ) {
    segment
    brand
    sku
  }
} `,
	};
};
